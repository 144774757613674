




























































































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import PercentIndicatorMixin from "@/mixins/percentIndicatorMixin";
import BotStatusIndicator from "./BotStatusIndicator.vue";
import DateFormatterMixin from "@/mixins/dateFormatterMixin";
import TooltipLabel from "@/components/general/TooltipLabel.vue";
import TradingBotStats from "@/utility/bots/tradingBotStats";

class CombinedBotStats {
  constructor(
    public activeBotsStartingBalance: number,
    public activeBotsCurrentBalance: number,
    public activeBotsAverageROI: number,
    public activeBotsCount: number,
    public activeBotsRoi: number,
    public activeBotsProfit: number,
    public marketRoiActiveBots: number,
    public marketProfitActiveBots: number,
    public activeBotsFees: number,
    public archivedBotsCount: number,
    public archivedBotsRoi: number,
    public archivedBotsProfit: number,
    public marketRoiArchivedBots: number,
    public marketProfitArchivedBots: number,
    public totalBotFees: number
  ) {}

  public get totalROI(): number {
    return (this.activeBotsRoi + this.archivedBotsRoi) / 2;
  }

  public get totalProfit(): number {
    return this.activeBotsProfit + this.archivedBotsProfit;
  }

  public get totalMarketROI(): number {
    return (this.marketRoiActiveBots + this.marketRoiArchivedBots) / 2;
  }

  public get totalMarketProfit(): number {
    return this.marketProfitActiveBots + this.marketProfitArchivedBots;
  }
}

@Component({
  mixins: [PercentIndicatorMixin, DateFormatterMixin],
  components: { BotStatusIndicator, TooltipLabel }
})
export default class UserBotsDialog extends Vue {
  // 🚩 Flag which determines whether the dialog should be shown
  @PropSync("showDialog", { type: Boolean })
  public show!: boolean;

  // 📦 Ids of the bots the user has activated
  @Prop({ default: "" })
  public username!: string;

  // 📦 Ids of the bots the user has activated
  @Prop({ default: "" })
  public email!: string;

  // 🚩 Flag for whether the dialog is expanded to a fullscreen
  private expanded: boolean = false;

  private activeBotsHeaders = [
    { text: "Bot", value: "uid" },
    { text: "Market", value: "market" },
    { text: "Starting Balance", value: "startingBalanceQuoteCurrency" },
    { text: "ROI", value: "roi" },
    { text: "Market ROI", value: "bah_roi" },
    { text: "Outperformance", value: "outperformance" },
    { text: "Activated", value: "start_time" },
    { text: "", value: "actions" }
  ];

  private archivedBotsHeaders = [
    { text: "Bot", value: "uid" },
    { text: "Market", value: "market" },
    { text: "ROI", value: "roi" },
    { text: "Market ROI", value: "bah_roi" },
    { text: "Outperformance", value: "outperformance" },
    { text: "Start Time", value: "start_time" },
    { text: "Stop Time", value: "stop_time" },
    { text: "Active For", value: "activeFor" },
    { text: "", value: "actions" }
  ];

  private combinedBotStats: CombinedBotStats | null = null;

  private get activeBots(): TradingBotStats[] {
    return this.bots.filter((bot: any) => bot.enabled);
  }

  private get archivedBots(): TradingBotStats[] {
    return this.bots.filter((bot: any) => !bot.enabled);
  }

  private bots: TradingBotStats[] = [];

  @Watch("show")
  private onDialogVisibilityChanged(val: boolean, oldVal: boolean) {
    if (val) {
      this.bots = [];
      this.combinedBotStats = null;
      this.fetchUserBots();
    }
  }

  private async fetchUserBots() {
    const response: any = await Vue.prototype.$api.post(
      "/api/proxy",
      "/api/v1/admin/users/bots",
      {
        username: this.email
      }
    );

    let data = response.data;

    this.bots = [];
    for (let [key, value] of Object.entries(response.data.bots_stats)) {
      this.bots.push(TradingBotStats.newInstance(value));
    }

    const combinedActiveBotsStartingBalance = this.activeBots
      .map((bot: TradingBotStats) => bot.startingBalance[1])
      .reduce((a, b) => a + b, 0);

    const combinedActiveBotsCurrentBalance = this.activeBots
      .map((bot: TradingBotStats) => bot.portfolioValue)
      .reduce((a, b) => a + b, 0);

    const averageRoiActiveBots =
      this.activeBots
        .map((bot: TradingBotStats) => bot.roi)
        .reduce((a, b) => a + b, 0) / this.activeBots.length;

    const combinedActiveBotsFees = this.activeBots
      .map((bot: TradingBotStats) => bot.in_fees)
      .reduce((a, b) => a + b, 0);

    const activeBotsMarketProfit = this.activeBots
      .map((bot: TradingBotStats) => bot.bah_profit)
      .reduce((a, b) => a + b, 0);

    const totalBotFees = this.bots
      .map((bot: TradingBotStats) => {
        return bot.in_fees;
      })
      .reduce((a, b) => a + b, 0);

    this.combinedBotStats = new CombinedBotStats(
      combinedActiveBotsStartingBalance,
      combinedActiveBotsCurrentBalance,
      averageRoiActiveBots,
      data.total_bots.true,
      ((combinedActiveBotsCurrentBalance - combinedActiveBotsStartingBalance) / combinedActiveBotsStartingBalance) * 100,
      data.total_profit.true,
      (data.total_bah_profit.true / combinedActiveBotsStartingBalance) * 100,
      activeBotsMarketProfit,
      combinedActiveBotsFees,
      data.total_bots.false,
      data.total_roi.false * 100,
      data.total_profit.false,
      data.total_bah_roi.false,
      data.total_bah_profit.false,
      totalBotFees
    );
  }
}
