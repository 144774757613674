var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-1",attrs:{"align":"center"}},[_c('RefreshIndicator',{attrs:{"lastRefreshTime":_vm.lastDataRefresh,"loading":_vm.areActiveBotsFetched},on:{"refreshData":function($event){return _vm.$store.dispatch('fetchActiveBots')}}})],1),_c('v-row',{staticClass:"mt-6"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search Bots...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"id":"bots-table","headers":_vm.headers,"items":_vm.activeBots,"items-per-page":-1,"search":_vm.search,"loading":_vm.areActiveBotsFetched,"loading-text":"Loading Bots...","group-by":"status_activity","show-group-by":""},on:{"click:row":_vm.onRowClicked},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
var remove = ref.remove;
return [_c('th',{attrs:{"colspan":"9"}},[_c('v-layout',{attrs:{"d-flex":"","align-center":""}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? "mdi-minus" : "mdi-plus")+" ")]),_c('div',{staticClass:"ml-3 text-body-2 font-weight-medium",on:{"click":toggle}},[_vm._v(" "+_vm._s(items[0].status_activity)+" [ "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(items.length))]),_vm._v(" ] [ "),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s((items.length / _vm.activeBots.length).toFixed(2) * 100 + "%")+" ")]),_vm._v(" ] ")]),_c('v-spacer'),_c('v-icon',{on:{"click":remove}},[_vm._v("mdi-close")])],1)],1)]}},{key:"item.status_activity",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status_activity))])]}},{key:"item.activated",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.activated)+" days ago")])]}},{key:"item.roi",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getIndicatorColor(item.roi * 100)},[_vm._v(" "+_vm._s(_vm.getIndicatorDisplayValue(item.roi * 100))+" ")])]}},{key:"item.current_balance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(item.current_balance.toFixed(2)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }