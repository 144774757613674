























































































































import Base from "@/views/Base.vue";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import LogMessage from "@/utility/logs/logMessage";
import DateFormatter from "@/mixins/dateFormatterMixin";
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";
import { isFunctionalityAllowedForUser } from "@/utils/permissionChecker";
import BotDetails from "@/utility/bots/botDetails";
import TradeHistoryTable from "./components/TradeHistoryTable.vue";
import OpenOrdersTable from "./components/OpenOrdersTable.vue";
import { sortItems } from "@/utils/sortingUtils";
import BalanceInUseDonutChart from "./components/BalanceInUseDonutChart.vue";
import CoinHeld from "@/utility/bots/CoinHeld";
import OpenOrder from "@/utility/bots/openOrder";
import PercentIndicator from "@/mixins/percentIndicatorMixin";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";

@Component({
  mixins: [DateFormatter, PermissionCheckerMixin, PercentIndicator],
  components: {
    TradeHistoryTable,
    OpenOrdersTable,
    BalanceInUseDonutChart,
    ConfirmationDialog,
    DownloadTradeLog: () => import("./components/DownloadTradeLog.vue")
  },
  props: {
    id: {
      type: String,
      required: true
    }
  }
})
export default class BotLogs extends Base {
  // 📦 Headers for the individual bot logs
  private logsTableHeaders = [
    { text: "Message", value: "message" },
    { text: "Level", value: "level" },
    { text: "Process", value: "process" },
    { text: "Date", value: "date" }
  ];

  // 📦 All the logs for the current bot
  private logMessages: LogMessage[] = [];

  // 📦 The display parameters about the currently selected bot
  private botDisplayParameters: any[] = [];

  // 📦 The current bot details returned by the API
  private bot: BotDetails | null = null;

  // 🚩 Flag for whether the logs are being fetched from the API
  private isLoading: boolean = false;

  // 🚩 Flag for whether the dialog for reactivating the bot should be shown to the user
  private showBotReactivationDialog: boolean = false;

  // 🤡 Example API response used for testing
  private exampleApiResponse = {
    1612191474.4875298: [
      { msg: "Failed to get candles", level: "ERROR", process: 45422 }
    ],
    1612193474.4875298: [
      { msg: "Failed to get exchange", level: "ERROR", process: 45422 }
    ]
  };

  // 📦 Balance in use per coin that should be displayed in the chart
  private get balanceInUsePerCoin(): CoinHeld[] {
    if (!this.bot) {
      return [];
    }

    const coinsUsed: CoinHeld[] = [];

    let baseCurrencyInOrder = 0;
    let primaryCurrencyInOrder = 0;

    // Take into consideration the amount in open orders
    this.bot?.openOrders.forEach((order: OpenOrder) => {
      switch (order.side) {
        case "BUY":
          baseCurrencyInOrder += order.volume * order.price;
          break;
        case "SELL":
          primaryCurrencyInOrder += order.volume;
          break;
      }
    });

    const primaryCurrencyValue =
      this.bot.portfolioValue - this.bot.currentBalance - baseCurrencyInOrder;

    const baseCurrencyValue = this.bot.currentBalance + baseCurrencyInOrder;

    coinsUsed.push(
      new CoinHeld(
        this.bot.market.split(":")[0],
        primaryCurrencyValue,
        (primaryCurrencyValue / this.bot.portfolioValue) * 100,
        this.bot.tokenBalance + primaryCurrencyInOrder
      )
    );
    coinsUsed.push(
      new CoinHeld(
        this.bot.market.split(":")[1],
        baseCurrencyValue,
        (baseCurrencyValue / this.bot.portfolioValue) * 100,
        baseCurrencyValue
      )
    );
    return coinsUsed.sort(
      (coinA: CoinHeld, coinB: CoinHeld) => coinB.value - coinA.value
    );
  }

  protected async mounted() {
    super.mounted();
    this.fetchBotLogs();
    this.fetchBotDetails();
  }

  /**
   * 🚀 Reactivate the particular bot on the API
   */
  private reactivateBot() {
    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/resurrectbot", {
        bot_id: this.$props.id
      })
      .then((response: any) => {
        this.showSuccessSnackbar("The bot was successfully reactivated!");
        this.bot!.enabled = true;
      });
  }

  /**
   * 🚀 Fetches the details about a specific bot
   */
  private fetchBotDetails() {
    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/adminbots", {
        uid: this.$props.id
      })
      .then((response: any) => {
        this.bot = BotDetails.newInstance(response.data);
        this.botDisplayParameters = [
          { text: "State", value: this.bot.enabled ? "Active" : "Archived" },
          { text: "Id", value: this.bot.uid },
          { text: "Strategy (Internal)", value: this.bot.strategy },
          { text: "Owner", value: this.bot.user },
          { text: "Bot Type", value: this.bot.strategyName },
          { text: "Exchange", value: this.bot.exchange },
          { text: "Market", value: this.bot.market + " " + this.bot.candles },
          {
            text: "Started On",
            value: new DateFormatter().formatDateFromAPI(this.bot.startTime)
          },
          {
            text: "Ended On",
            value: this.bot.stopTime
              ? new DateFormatter().formatDateFromAPI(this.bot.stopTime)
              : "Still Active"
          },
          {
            text: "Starting Balance",
            value:
              this.bot.startingBalance[1] + " " + this.bot.market.split(":")[1]
          },
          {
            text: "Current Balance",
            value: this.bot.currentBalance + " " + this.bot.market.split(":")[1]
          },
          {
            text: "Token Balance",
            value: this.bot.tokenBalance + " " + this.bot.market.split(":")[0]
          },
          {
            text: "Minimum Balance",
            value: "$" + this.bot.minBalance.toFixed(2)
          },
          {
            text: "Maximum Balance",
            value: "$" + this.bot.maxBalance.toFixed(2)
          },
          {
            text: "Exchange Fees",
            value: "$" + this.bot.inFees.toFixed(5)
          },
          {
            text: "ROI",
            value: this.bot.roi,
            colorized: true
          },
          {
            text: "Buy & Hold ROI",
            value: this.bot.bahRoi,
            colorized: true
          },
          {
            text: "AVG ROI / Month",
            value: this.bot.avgRoiMonth,
            colorized: true
          },
          {
            text: "AVG ROI / Trade",
            value: this.bot.avgRoiTrade,
            colorized: true
          },
          {
            text: "Stop-Loss",
            value: this.bot.stopLoss
              ? JSON.stringify(this.bot.stopLoss)
              : "None"
          }
        ];
      });
  }

  /**
   * 🚀 Fetch bots logs from the API
   */
  private async fetchBotLogs() {
    if (!isFunctionalityAllowedForUser("logs", "view")) {
      return;
    }

    this.logMessages = [];
    this.isLoading = true;

    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/logs", {
        leaf: this.$props.id
      })
      .then((response: any) => {
        for (const [key, value] of Object.entries(response.data)) {
          (value as any[]).forEach((logMessage: any) => {
            this.logMessages.push(
              new LogMessage(
                key,
                logMessage.level,
                logMessage.msg,
                logMessage.process
              )
            );
          });
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * 📦 Default sorting by bot start date descending
   */
  private pagination = {
    sortBy: ["date"],
    sortDesc: [true],
    mustSort: true,
    itemsPerPage: 15
  };

  /**
   * 🔨 Custom sorting functionality used in the bot table
   */
  private sortByDate(items: any[], index: string[], isDesc: boolean[]) {
    return sortItems("date", items, index, isDesc);
  }
}
