import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import ListBot from "@/utility/listBot";
const _ = require("lodash");

/**
 * 🧩 Module which aims to combine logic for the bots page
 */
@Module
export default class BotsModule extends VuexModule {
  // 📦 The cached active bots
  public activeBots: ListBot[] = [];

  // 🚩 Flag for whether the active bots are fetched from the API
  public areActiveBotsFetched: boolean = false;

  // 📦 The last time the active bots has been refreshed
  public lastRefreshOfActiveBots: Date | null = null;

  // 📦 The cached inactive bots
  public inactiveBots: ListBot[] = [];

  // 🚩 Flag for whether the inactive bots are fetched from the API
  public areInactiveBotsFetched: boolean = false;

  // 📦 The last time the inactive bots data has been refreshed
  public lastRefreshOfInactiveBots: Date | null = null;

  private botsPerPage: number = 15;

  /**
   * 👾 Mutation for setting the newly fetched active bots
   * @param newActiveBots
   */
  @Mutation
  private setActiveBots(newActiveBots: ListBot[]) {
    this.activeBots = newActiveBots;
  }

  /**
   * 👾 Mutation which sets the flag for whether the active bots are fetched
   * @param areFetched
   */
  @Mutation
  private setActiveBotsAreFetched(areFetched: boolean) {
    this.areActiveBotsFetched = areFetched;
  }

  /**
   * 👾 Mutation for setting the last time the active bots data was refreshed
   * @param newRefreshTime
   */
  @Mutation
  private setActiveBotsLastRefreshTime(newRefreshTime: Date) {
    this.lastRefreshOfActiveBots = newRefreshTime;
  }

  /**
   * 👾 Mutation for setting the newly fetched inactive bots
   * @param newInactiveBots
   */
  @Mutation
  private setInactiveBots(newInactiveBots: ListBot[]) {
    this.inactiveBots = newInactiveBots;
  }

  /**
   * 👾 Mutation which sets the flag for whether the inactive bots are fetched
   * @param areFetched
   */
  @Mutation
  private setInactiveBotsAreFetched(areFetched: boolean) {
    this.areInactiveBotsFetched = areFetched;
  }

  /**
   * 👾 Mutation for setting the last time the inactive bots data was refreshed
   * @param newRefreshTime
   */
  @Mutation
  private setInactiveBotsLastRefreshTime(newRefreshTime: Date) {
    this.lastRefreshOfInactiveBots = newRefreshTime;
  }

  /**
   * 🔨 Itteratively fetch all active bots from the API
   */
  @Action
  public async fetchActiveBots() {
    let newlyFetchedBots: ListBot[] = [];
    let currentBatchOfBots: ListBot[] = [];
    let offset = 0;

    this.context.commit("setActiveBotsAreFetched", true);
    this.context.commit("setActiveBots", []);

    try {
      do {
        currentBatchOfBots = await this.context.dispatch(
          "fetchActiveBotsFromAPI",
          offset
        );
        newlyFetchedBots = newlyFetchedBots.concat(currentBatchOfBots);

        offset += currentBatchOfBots.length;
      } while (currentBatchOfBots.length === this.botsPerPage);

      newlyFetchedBots.forEach(
        (bot: ListBot) => {
          let newActivityStatus = Object.values(bot.status_activity)[0] as string;
          bot.status_activity = _.startCase(_.camelCase(newActivityStatus.replaceAll("-", " ")));
        }
      );

      this.context.commit("setActiveBots", newlyFetchedBots);
      this.context.commit("setActiveBotsAreFetched", false);
      this.context.commit("setActiveBotsLastRefreshTime", new Date());
    } catch (error) {
      this.context.commit("setActiveBotsAreFetched", false);
    }
  }

  /**
   * 🚀 Action which fetches a batch of active bots from the API
   * @param offset
   * @returns
   */
  @Action
  private async fetchActiveBotsFromAPI(offset: number): Promise<ListBot[]> {
    const response = await Vue.prototype.$api.get(
      "/api/proxy",
      "/api/v1/admin/adminbots?offset=" + offset + "&count=" + this.botsPerPage
    );
    return Object.values(response.data as Map<string, ListBot>);
  }

  /**
   * 🔨 Itteratively fetch all inactive bots from the API
   */
  @Action
  public async fetchInactiveBots() {
    let newlyFetchedBots: ListBot[] = [];
    let currentBatchOfBots: ListBot[] = [];
    let offset = 0;

    this.context.commit("setInactiveBotsAreFetched", true);
    this.context.commit("setInactiveBots", []);

    do {
      currentBatchOfBots = await this.context.dispatch(
        "fetchInactiveBotsFromAPI",
        offset
      );
      newlyFetchedBots = newlyFetchedBots.concat(currentBatchOfBots);
      offset += currentBatchOfBots.length;
    } while (currentBatchOfBots.length === this.botsPerPage);

    this.context.commit("setInactiveBots", newlyFetchedBots);
    this.context.commit("setInactiveBotsAreFetched", false);
    this.context.commit("setInactiveBotsLastRefreshTime", new Date());
  }

  /**
   * 🚀 Action which fetches a batch of inactive bots from the API
   * @param offset
   * @returns
   */
  @Action
  private async fetchInactiveBotsFromAPI(offset: number): Promise<ListBot[]> {
    const response: any = await Vue.prototype.$api.get(
      "/api/proxy",
      "/api/v1/admin/admininactivebots?offset=" +
      offset +
      "&count=" +
      this.botsPerPage
    );
    return Object.values(response.data as Map<string, ListBot>);
  }
}
