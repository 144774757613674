

























import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import Users from "@/views/users/Users.vue";
import Requests from "@/views/users/Requests.vue";
import Invitations from "@/views/users/Invitations.vue";

@Component({ components: { Users, Requests, Invitations } })
export default class UsersBase extends Base {
  protected async mounted() {
    super.mounted();
  }
}
