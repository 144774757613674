/**
 * 📄
 * Model representing a single line in the trade history table
 */
export default class tradeLogEntry {
  constructor(
    public botId: string,
    public botName: string,
    public type: string,
    public date: number,
    public price: number,
    public market: string,
    public priceCurrency: string,
    public amount: number,
    public currency: string,
    public startingBotBalance: number,
    public balance: number,
    public fee: number,
    public fee_asset: string,
    public change: number | undefined,
    public order_type?: TradeOrderType
  ) {}

  /**
   * 🚩 Flag for whether the current trade type is limit
   */
  public get isLimitOrder(): boolean {
    return this.order_type == TradeOrderType.LimitOrder;
  }
}

export enum TradeOrderType {
  MarketOrder = "MARKET",
  LimitOrder = "LIMIT"
}
