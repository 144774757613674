import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

/**
 * 🎨 The possible types of snackbar events
 */
export enum SnackbarEventType {
  // Displays informational text
  Informational = "Informational",
  // Displays an error
  Error = "Error",
  // Displays a successful message
  Success = "Success"
}

/**
 * 🧩 Module which provides an event bus for showing snackbar messages
 */
@Module
export default class SnackbarModule extends VuexModule {
  /**
   * 📦 Message to be displayed in the snackbar
   */
  public message: string = "";

  /**
   * 📦 The type of snackbar that should be shown
   */
  public type: SnackbarEventType = SnackbarEventType.Informational;

  /**
   * 👾 Mutation which sets the snackbar message and type to be shown
   * @param payload - object containing the snackbar message and type
   */
  @Mutation
  public publishSnackbarEvent(payload: any) {
    this.message = payload.message;
    this.type = payload.eventType;

    if (payload != null) {
      this.message = "";
      this.type = SnackbarEventType.Informational;
    }
  }

  /**
   * 🔨 Action which publishes an error snackbar event
   * @param message
   */
  @Action
  public publishSnackbarErrorEvent(message: string) {
    this.context.commit("publishSnackbarEvent", {
      message: message,
      type: SnackbarEventType.Error,
    });
  }

  /**
   * 🔨 Action which publishes a success snackbar event
   * @param message
   */
  @Action
  public publishSnackbarSuccessEvent(message: string) {
    this.context.commit("publishSnackbarEvent", {
      message: message,
      type: SnackbarEventType.Success,
    });
  }

  /**
 * 🔨 Action which publishes an informational snackbar event
 * @param message
 */
  @Action
  public publishSnackbarInformationEvent(message: string) {
    this.context.commit("publishSnackbarEvent", {
      message: message,
      type: SnackbarEventType.Informational,
    });
  }
}
