import store from "@/store";
import AdminRoleWithPermissions from "@/utility/admin-users/adminRoleWithPermissions";

/**
 * 🔬 Checks whether the current user is permitted to access a certain functionality
 * @param page 
 * @param permission 
 * @returns 
 */
export function isFunctionalityAllowedForUser(page: string, permission: string): boolean {
  const currentUserPermissions: AdminRoleWithPermissions = (store.state as any).globalStateModule.currentUserRoleWithPermissions;
  const permissions: string[] = currentUserPermissions.permissions[page];
  return permissions.includes(permission);
}

/**
 * 🔬 Checks whether the current user is permitted to access a certain page
 * @param page 
 * @returns 
 */
export function isPageAllowedForUser(page: string, specificPermissionToEnablePage: string = "view"): boolean {
  const currentUserPermissions: AdminRoleWithPermissions = (store.state as any).globalStateModule.currentUserRoleWithPermissions;
  const permissions: string[] = currentUserPermissions.permissions[page];
  return permissions.length > 0 && permissions !== undefined && permissions.includes(specificPermissionToEnablePage);
}
