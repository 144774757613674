/**
 * 📄 The possible bot statuses
 */
export enum BotStatus {
  Archived = "Archived",
  Active = "Active",
  SettingUp = "Setting Up",
  MarketScanFailed = "Market Scan Failed",
  PotentialIssues = "Potential Issues"
}

/**
 * 🔨 Returns the bot status based on the last trade attempt and candles of the bot
 * @param lastTradeAttempt
 * @param candlestickSize
 * @param enabled
 * @returns
 */
export function getBotStatus(
  lastTradeAttempt: number,
  candlestickSize: string,
  enabled?: boolean
): BotStatus {
  if (enabled == false) {
    return BotStatus.Archived;
  }

  const now = new Date().getTime() / 1000;
  const candleStickSizeInSeconds = getSecondsFromCandleStickSize(
    candlestickSize
  );

  if (lastTradeAttempt == null) {
    return BotStatus.SettingUp;
  }

  if (lastTradeAttempt < now - 4 * candleStickSizeInSeconds) {
    return BotStatus.MarketScanFailed;
  }

  if (
    lastTradeAttempt >= now - 4 * candleStickSizeInSeconds &&
    lastTradeAttempt <= now
  ) {
    return BotStatus.Active;
  }
  return BotStatus.PotentialIssues;
}

/**
 * 🔨 Parses the candlestick size and converts it to seconds
 */
export function getSecondsFromCandleStickSize(candleStickSize: string): number {
  const candlesSize: number = Number(candleStickSize.match(/\d+/)!![0]);
  const candlesUnit: string = candleStickSize.match(/[a-zA-Z]+/g)!![0];
  switch (candlesUnit) {
    case "h":
      return candlesSize * 3600;
    case "m":
      return candlesSize * 60;
    default:
      return -1;
  }
}

/**
 * 🎨 Returns the color based on the bot status
 * @param botStatus 
 * @returns 
 */
export function getColorBasedBotStatus(botStatus: BotStatus) {
  switch (botStatus) {
    case BotStatus.Active:
      return "green";
    case BotStatus.SettingUp:
      return "darkYellow";
    case BotStatus.MarketScanFailed:
      return "red";
    case BotStatus.PotentialIssues:
      return "darkYellow";
    default:
      return "grey";
  }
}
