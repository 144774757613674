
import OpenOrder from "./openOrder";
import TradeLogEntry, { TradeOrderType } from "./tradeLogEntry";

/**
 * 📈
 * Model representing the stats of a certain trading bot
 */
export default class BotDetails {
  public roi: number;
  public profit: number;

  constructor(
    public user: string,
    public currentBalance: number,
    public tokenBalance: number,
    public portfolioValue: number,
    public maxBalance: number,
    public minBalance: number,
    public uid: string,
    public exchange: string,
    public strategy: string,
    public strategyName: string,
    public inFees: number,
    public market: string,
    public candles: string,
    public mlBoost: boolean,
    public tradeLog: TradeLogEntry[],
    public startingBalance: number[],
    public startTime: number,
    public stopTime: number,
    public bahRoi: number,
    public enabled: boolean,
    public avgFee: number,
    public avgRoiTrade: number,
    public avgRoiMonth: number,
    public image: string,
    public description: string,
    public activityStatuses: any[],
    public lastTradeAttempt?: number,
    public openOrders?: any,
    public stopLoss?: any
  ) {
    this.portfolioValue = portfolioValue ? portfolioValue : currentBalance;
    tradeLog.sort(
      (a: TradeLogEntry, b: TradeLogEntry) =>
        new Date(Number(a.date)).getTime() - new Date(Number(b.date)).getTime()
    );
    this.tradeLog = tradeLog.map((trade) => {
      return new TradeLogEntry(
        uid,
        this.strategyName,
        trade.type.toUpperCase(),
        trade.date,
        trade.price,
        this.market,
        this.market.split(":")[1],
        trade.amount,
        this.market.split(":")[0],
        startingBalance[1],
        trade.balance,
        trade.fee,
        trade.fee_asset,
        trade.change != undefined ? trade.change * 100 : undefined,
        trade.order_type
      );
    });
    this.openOrders = this.mapOpenOrders(openOrders);
    this.bahRoi =
      bahRoi != null ? bahRoi * 100 : this.locallyCalculatedBuyAndHoldRoi;
    this.avgFee = avgFee * 100;
    this.avgRoiTrade = avgRoiTrade * 100;
    this.avgRoiMonth = avgRoiMonth * 100;
    this.roi =
      ((this.portfolioValue - this.startingBalance[1]) /
        this.startingBalance[1]) *
      100;
    this.profit = this.portfolioValue - this.startingBalance[1];
  }

  /**
   * Converts a raw open orders object into an array of open orders
   * @param rawOpenOrders
   */
  private mapOpenOrders(rawOpenOrders: any): OpenOrder[] {
    if (rawOpenOrders == undefined) {
      return [];
    }

    let openOrders: any[] = Object.values(rawOpenOrders);

    if (openOrders.length == 0) {
      return [];
    }

    return openOrders
      .map((rawOpenOrder) => {
        return new OpenOrder(
          rawOpenOrder.txid,
          rawOpenOrder.side.toUpperCase(),
          rawOpenOrder.volume,
          rawOpenOrder.price,
          rawOpenOrder.expire_time,
          rawOpenOrder.createtime,
          rawOpenOrder.org_vol,
          this.market,
          rawOpenOrder.order_type,
          this.market.split(":")[0],
          this.market.split(":")[1]
        );
      });

  }

  static newInstance(response: any): BotDetails {
    return new BotDetails(
      response.user,
      response.curBalance,
      response.tokBalance,
      response.portfolioValue,
      response.max_balance,
      response.min_balance,
      response.uid,
      response.exchange,
      response.strategy,
      response.strategy_name,
      response.in_fees,
      response.market,
      response.candles,
      response.ml_boost,
      response.trade_log,
      response.startingBalance,
      response.start_time,
      response.stop_time,
      response.bah_roi,
      response.enabled,
      response.avg_fee,
      response.avg_roi_trade,
      response.avg_roi_month,
      response.image,
      response.description,
      response.status_activity,
      response.last_trade_attempt,
      response.open_orders,
      response.stop_loss
    );
  }

  //Calculated
  get locallyCalculatedBuyAndHoldRoi(): number {
    if (this.tradeLog.length > 1) {
      return (
        ((this.tradeLog[this.tradeLog.length - 1].price -
          this.tradeLog[0].price) /
          this.tradeLog[0].price) * 100
      );
    } else {
      return 0;
    }
  }

  /**
   * USED FOR TESTING ONLY
   * Example of a running bot
   */
  static example: BotDetails = new BotDetails(
    "email@email.com",
    110,
    0,
    111,
    100,
    50,
    "1",
    "Kraken",
    "MA Crossover",
    "MA Crossover",
    0,
    "BTC:USDT",
    "15m",
    false,
    BotDetails.generateTradeLog(),
    [0, 100],
    new Date().getTime(),
    new Date().getTime(),
    -0.004814253,
    false,
    0.012,
    0.15,
    0.13,
    "url",
    "description",
    [],
    new Date().getTime(),
    BotDetails.generateOpenOrders(),
    null
  );

  /**
   * USED FOR TESTING ONLY
   * Generates a random trade log of 10 to 20 trades
   */
  private static generateTradeLog(): TradeLogEntry[] {
    let tradeLog = [];

    for (let i = 0; i < Math.random() * 10 + 10; i++) {
      let type = i % 2 == 0 ? "sell" : "buy";
      tradeLog.push(
        new TradeLogEntry(
          "",
          "MA Crossover",
          type,
          Math.floor(Math.random() * (1600353061 - 1600243000)) + 1600243000,
          Math.random() * 10000 + 9264.0,
          "BTC:USDT",
          "USDT",
          (Math.random() * 20 + 5) / 100,
          "BTC",
          i * 10,
          100,
          (Math.random() * 16 + 5) / 100000,
          "USDT",
          Math.floor(Math.random() * -0.001) + 0.005,
          TradeOrderType.LimitOrder
        )
      );
    }
    return tradeLog;
  }

  /**
   * USED FOR TESTING ONLY
   * Generates 5 to 10 random open orders
   */
  private static generateOpenOrders() {
    let exmapleOpenOrders: any = {};

    for (let i = 0; i < Math.random() * 5 + 5; i++) {
      let id = (Math.random() * 15 + 1).toString();
      exmapleOpenOrders[id] = {
        txId: id,
        side: i % 2 == 0 ? "BUY" : "SELL",
        order_type: "Limit",
        vol: Math.random() * 50 + 50,
        price: Math.random() * 50 + 50,
        expire_time:
          Math.floor(Math.random() * (1600353061 - 1600243000)) + 1600243000,
        createtime:
          Math.floor(Math.random() * (1600353061 - 1600243000)) + 1600243000,
        org_volume: Math.random() * 50 + 50,
      };
    }

    return exmapleOpenOrders;
  }
}
