





















































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import Invitation from "@/utility/invitation";
import { GeneralEventTypes } from "@/modules/eventBus/generalEventTypes";

@Component
export default class Invitations extends Base {
  data() {
    return {
      sortBy: "period",
      sortDesc: false
    };
  }

  private showDialog: boolean = false;
  private emailToRevoke: string | null = null;

  private users: Invitation[] = [];
  private headers = [
    { text: "Name", value: "name" },
    { text: "E-mail", value: "email" },
    { text: "Last Invited", value: "period" }
  ];

  // 🚩 Flag for whether the invitations are loading
  private isLoading: boolean = false;

  // 📦 The currently searched user in the search box
  private searchedUser: string = "";

  protected async mounted() {
    await super.mounted();
    this.fetchInvitations();

    this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "updateEvent" &&
        mutation.payload == GeneralEventTypes.UpdateInvitations
      ) {
        this.fetchInvitations();
      }
    });
  }

  private async fetchInvitations() {
    this.isLoading = true;
    Vue.prototype.$api
      .get("/api/proxy", "/api/v1/admin/invitations")
      .then((response: any) => (this.users = response.data))
      .finally(() => (this.isLoading = false));
  }

  private async resendInvitation(email: string) {
    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/invitations", { email })
      .then((response: any) => {
        super.showSuccessSnackbar("The invitation was successfully resent");
        this.fetchInvitations();
      });
  }

  private async revokeInvitation(email: string) {
    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/revokeinvitation", { email })
      .then((response: any) => {
        super.showSuccessSnackbar("The invitation was successfully revoked");
        this.fetchInvitations();
      });
  }

  private openRevokeInvitationDialog(emailToRevoke: string) {
    this.emailToRevoke = emailToRevoke;
    this.showDialog = true;
  }
}
