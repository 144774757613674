import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import AdminUser from "@/utility/admin-users/adminUser";
import { showSuccessSnackbar } from "@/utils/snackbarUtils";
import camelcaseKeys from "camelcase-keys";

/**
 * 🧩 Module which aims to combine logic for the admin users page
 */
@Module
export default class AdminUsersModule extends VuexModule {
  // 📦 The cached admin users
  public adminUsers: AdminUser[] = [];

  /**
   * 👾 Mutation for setting the newly fetched admin users
   * @param newUsers
   */
  @Mutation
  private setAdminUsers(newUsers: AdminUser[]) {
    this.adminUsers = newUsers;
  }

  /**
   * 🚀 Fetches all the users of the platform from the API
   */
  @Action
  public async fetchAdminUsers() {
    const response = await Vue.prototype.$api.get("/api/admins");
    this.context.commit("setAdminUsers", camelcaseKeys(response.data));
  }

  /**
   * 🚀 Creates a new admin user on the API
   */
  @Action
  public async createAdminUser(newAdminUser: any) {
    const response = await Vue.prototype.$api.post("/api/createadmin", null, {
      username: newAdminUser.username,
      password: newAdminUser.password,
      role: newAdminUser.role
    });
    this.context.dispatch("fetchAdminUsers");
    showSuccessSnackbar("New admin user was successfully created!");
  }

  /**
   * 🚀 Updates an existing admin user on the API
   */
  @Action
  public async updateAdminUser(modifiedAdminUser: AdminUser) {
    const response = await Vue.prototype.$api.post("/api/updateadminrole", null, {
      username: modifiedAdminUser.username,
      role: modifiedAdminUser.role
    });
    showSuccessSnackbar(modifiedAdminUser.username + "'s role was successfully updated to " + modifiedAdminUser.role);
  }

  /**
   * 🚀 Deletes an existing admin user from the API
   */
  @Action
  public async deleteAdminUser(adminUserName: string) {
    console.log(adminUserName)
    const response = await Vue.prototype.$api.delete("/api/deleteadmin", null, {
      username: adminUserName
    });
    this.context.dispatch("fetchAdminUsers");
    showSuccessSnackbar(adminUserName + " was sucessfully deleted");
  }
}
