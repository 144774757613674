
import Vue from "vue";
import Chart from "chart.js";
import Component from "vue-class-component";

@Component
export default class BaseChart extends Vue {
  /**
   * 📦📈 Chart which displays the balance in use over time
   */
  protected chart: Chart | null = null;

  protected chartElementId(): string {
    return "";
  }

  protected formatYAxisData(
    value: number,
    index: number,
    values: number[]
  ): string {
    return (
      "$" +
      (Math.max(...values) - Math.min(...values) >= 50
        ? value.toFixed(0)
        : value.toFixed(2))
    );
  }

  /**
   * 🎨 Draws a dynamic chart on the canvas which is responsible for displaying the balance in use
   */
  protected createChart(
    dateLabels: Date[],
    dataset: any[],
    selectedFilterInDays: number
  ): void {
    if (this.chart == null) {
      let container: any = document.getElementById(this.chartElementId());
      this.chart = new Chart(container, {
        type: "line",
        data: {
          labels: dateLabels,
          datasets: this.getDataSets()
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            xAxes: this.getXAxis(selectedFilterInDays),
            yAxes: this.getYAxes(dataset)
          },
          animation: {
            duration: 750
          }
        }
      });
    } else {
      this.chart.data.datasets = this.getDataSets();
      this.chart.data.labels = dateLabels;
      this.chart.config.options!!.scales!!.yAxes = this.getYAxes(dataset);
      this.chart.options.scales!!.xAxes = this.getXAxis(selectedFilterInDays);
      this.chart.update();
    }
  }

  /**
   * 🔨 Returns the datasets with their display properties that should be displayed on the chart
   */
  protected getDataSets(): Chart.ChartDataSets[] | undefined {
    return undefined;
  }

  /**
   * 🔨 Determines the x axis label format
   */
  private getXAxis(selectedTimeFilter: number): any {
    // Show x labels as hours
    if (selectedTimeFilter == 1) {
      return [
        {
          type: "time",
          time: {
            tooltipFormat: "DD/MM/YYYY HH:mm:ss",
            unit: "hour",
            unitStepSize: 4,
            displayFormats: {
              hour: "HH:mm"
            }
          }
        }
      ];
    }

    // Show x labels as days
    return [
      {
        type: "time",
        time: {
          tooltipFormat: "DD/MM/YYYY HH:mm:ss",
          unit: "day",
          displayFormats: {
            day: "D MMM"
          }
        }
      }
    ];
  }

  /**
   * 🔨 Calculates a dynamic y axis based on the provided dataset
   */
  protected getYAxes(dataset: any[]): any {
    // Minimal value of the dataset
    let minValue: number = Math.min(...dataset);

    // Maximum value of the dataset
    let maxValue: number = Math.max(...dataset);

    // Boundary which is equal to 5% of the difference between the maximum and minimum value of the dataset
    let chartBoundary: number =
      maxValue - minValue == 0
        ? Math.ceil(maxValue * 0.1)
        : Math.ceil((maxValue - minValue) * 0.05);

    // Minimum y value that should be displayed on the chart
    let minDisplayValue: number = this.getMinDisplayValue(
      minValue,
      maxValue,
      chartBoundary
    );

    // Maximum y value that should be displayed on the chart
    let maxDisplayValue: number = this.getMaxDisplayValue(
      minValue,
      maxValue,
      chartBoundary
    );

    // Step size which is equal to 15% of the difference between the maximum and minimum display value of the dataset
    let stepSize: number =
      maxDisplayValue - minDisplayValue == 0
        ? 1
        : (maxDisplayValue - minDisplayValue) * 0.15;

    return [
      {
        ticks: {
          min: minDisplayValue,
          max: maxDisplayValue,
          stepSize: stepSize,
          maxTicksLimit: 8,
          callback: this.formatYAxisData
        },
        stacked: false
      }
    ];
  }

  /**
   * 🏗 Calculates and returns the minimum display value
   */
  private getMinDisplayValue(
    minValue: number,
    maxValue: number,
    chartBoundary: number
  ) {
    if (minValue <= 0) {
      return 0;
    }

    if (maxValue - minValue < 1) {
      return minValue + -chartBoundary > 0
        ? Math.floor(minValue + -chartBoundary)
        : 0;
    }

    return Math.floor(minValue - 1);
  }

  /**
   * 🏗 Calculates and returns the maximum display value
   */
  private getMaxDisplayValue(
    minValue: number,
    maxValue: number,
    chartBoundary: number
  ) {
    if (maxValue <= 0) {
      return 5;
    }

    if (maxValue - minValue < 1) {
      return maxValue + chartBoundary > 0
        ? Math.ceil(maxValue + chartBoundary)
        : 0;
    }

    return Math.ceil(maxValue + 1);
  }
}
