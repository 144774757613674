


























import { Component, Watch, Prop } from "vue-property-decorator";
import BaseChart from "./BaseChart.vue";

@Component
export default class TradesMadeChart extends BaseChart {
  // 📦 The selected filter for days
  @Prop({ default: 0 })
  public selectedFilterInDays!: number;

  // 🚩 Flag for whether a loading indicator should be shown
  @Prop({ required: true, default: false })
  public isLoading!: boolean;

  // 📦 Dataset to be displayed
  @Prop({ required: true })
  public dataset!: any[];

  // 📦 Dates for point on the chart
  @Prop({ required: true, default: [] })
  public dateLabels!: Date[];

  // 🎨 Possible views that can be shown in this component
  private COMPONENTS_TO_SHOW = {
    LOADING: 0,
    CHART: 1
  };

  // 📦 The currently displayed component
  private currentlyShownComponent: number = this.COMPONENTS_TO_SHOW.CHART;

  /**
   * 👂 Listener used in order to determine the selected chart filter mode
   */
  @Watch("dataset")
  private onDatasetChanged(val: any[], oldVal?: any[]) {
    this.updateDataset(val);
  }

  /**
   * 👂 Listener used in order to determine the selected chart filter mode
   */
  @Watch("selectedFilterInDays")
  private onSelectedFilterInDaysChanged(val: number, oldVal?: number) {
    this.updateDataset(this.dataset);
  }

  /**
   * 👂 Listener used in order to determine the selected chart filter mode
   */
  @Watch("isLoading")
  private onIsLoadingChanged(val: boolean, oldVal?: boolean) {
    if (val) {
      this.currentlyShownComponent = this.COMPONENTS_TO_SHOW.LOADING;
    } else {
      this.currentlyShownComponent = this.COMPONENTS_TO_SHOW.CHART;
    }
  }

  /**
   * 🏗 Updates the current dataset
   */
  private updateDataset(newDataSet: any[]) {
    let values: number[] = [];

    newDataSet.forEach((tradesMade: any) => {
      values.push(tradesMade.buy ? tradesMade.buy : null);
      values.push(tradesMade.sell ? tradesMade.sell : null);
    });

    this.createChart(this.dateLabels, values, this.selectedFilterInDays);
  }

  /**
   * 🏗 The element ID of the current chart
   */
  protected chartElementId(): string {
    return "container-trades-made";
  }

  /**
   * 🔨 Formats the data representation for the Y axis
   */
  protected formatYAxisData(
    value: number,
    index: number,
    values: number[]
  ): string {
    return value.toFixed(0);
  }

  /**
   * 🔨 Returns the datasets with their display porperties that should be displayed on the chart
   */
  protected getDataSets() {
    let dataSets = [];

    if (this.dataset.length > 0) {
      dataSets.push({
        label: "Buy Trades",
        data: this.dataset.map((trades: any) => trades.buy),
        borderColor: "#00B300",
        fill: false,
        pointRadius: 2,
        borderWidth: 2
      });

      dataSets.push({
        label: "Sell Trades",
        data: this.dataset.map((trades: any) => trades.sell),
        borderColor: "#FF0000",
        fill: false,
        pointRadius: 2,
        borderWidth: 2
      });
    }

    return dataSets;
  }
}
