import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import axios, { AxiosError } from 'axios'
import VueAxios from 'vue-axios'
import vuetify from '@/plugins/vuetify'
import store from '@/store/index'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import ApiHelper, { ApiEvents } from '@/api/apiHelper'

Vue.use(VueAxios, axios)
Vue.use(Vuex)
Vue.config.productionTip = false

Vue.prototype.$api = new ApiHelper();
Vue.prototype.$api.on(ApiEvents.Error, (error: AxiosError) => {
  store.dispatch("publishSnackbarErrorEvent", error.response?.data);
});

new Vue({
  router,
  store,
  vuetify: vuetify as any,
  render: h => h(App)
}).$mount('#app')
