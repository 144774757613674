


























































































































































































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Analytics from "@/utility/analytics";
import ActiveBotsChart from "./charts/ActiveBotsChart.vue";
import TotalExchangesChart from "./charts/TotalExchangesChart.vue";
import AverageBotBalanceChart from "./charts/AverageBotBalanceChart.vue";
import BalanceInUse from "./charts/BalanceInUseChart.vue";
import TradesMadeChart from "./charts/TradesMadeChart.vue";
import TotalUsersChart from "./charts/TotalUsersChart.vue";
import PercentIndicatorMixin from "@/mixins/percentIndicatorMixin";
import AverageActiveBotsPerUserChart from "./charts/AverageActiveBotsPerUserChart.vue";
import AverageBalanceInUsePerUserChart from "./charts/AverageBalanceInUsePerUserChart.vue";
import TradeVolumeChart from "./charts/TradeVolumeChart.vue";

@Component({
  components: {
    ActiveBotsChart,
    TotalExchangesChart,
    BalanceInUse,
    AverageBotBalanceChart,
    TradesMadeChart,
    TotalUsersChart,
    AverageActiveBotsPerUserChart,
    AverageBalanceInUsePerUserChart,
    TradeVolumeChart
  },
  mixins: [PercentIndicatorMixin]
})
export default class AnalyticsChartsWrapper extends Vue {
  // 📃 Possible time filters for the chart
  private chartFilters = [
    "Day",
    "Week",
    "Month",
    "Quarter",
    "Year",
    "Since Start"
  ];

  // 📦 The selected porfolio chart filter
  private selectedFilter: string = "";

  // 📦 The selected porfolio chart filter in days
  private selectedFilterInDays: number = 1;

  // 📦 The analytics data response received from the API
  private originalAnalyticsData: Map<number, Analytics> = new Map();

  // 📦 The possible AI strategies that filtering can be done by
  private availableAIStrategies: string[] = [];

  // 📦 The selected AI strategy used for filtering the charts values
  private selectedAIStrategy: string | null = null;

  // 📦 The date labels for the charts' x-axis for the selected period
  private dateLabels: Date[] = [];

  // 📦 The dataset for all of the connected exchanges for the selected period
  private totalConnectedExchangesDataSet: number[] = [];

  // 📦 the dataset for all the created users for the selected period
  private totalUsersDataset: number[] = [];

  // 📦 The dataset for all of the trades made for the selected period
  private tradesMadeDataset: any[] = [];

  // 📦 The dataset for all of the active bots for the selected period
  private activeBotsDataset: number[] = [];

  // 📦 The dataset for all the balance in use entries for the selected period
  private balanceInUseDataset: number[] = [];

  // 📦 The dataset for all of the average bot balance entries for the selected period
  private averageBotBalanceDataSet: number[] = [];

  // 📦 The dataset for all of the average active bot entries for the selected period
  private averageActiveBotsPerAccount: Map<Date, number> = new Map();

  // 📦 The dataset for all of the average balance in use per account
  private averageBalanceInUsePerAccount: Map<Date, number> = new Map();

  // 📦 The dataset for all of the trade volume entries for the selected period
  private tradeVolumeDataset: Map<Date, number> = new Map();

  // 🚩 Flag for whether new analytics data is being fetched
  private isLoadingNewData: boolean = false;

  // 📦 The selected subscription type that the chart should be filtered by
  private selectedSubscriptionType: string = this.availableSubscriptionTiers[0];

  // 📦 The subscription tiers that can be manually switched in between
  private get availableSubscriptionTiers(): string[] {
    const tiers: string[] = this.$store.getters.subscriptionTypes;
    tiers.unshift("All");
    return tiers;
  }

  // 🔨 Getter which calculates the total % change for a certain dataset for a particular period
  private get totalChangeInBasicDatasetPeriod(): (
    dateaset: number[]
  ) => number {
    return (dataset: number[]): number => {
      return (
        ((Number(dataset[dataset.length - 1]) - Number(dataset[0])) /
          Number(dataset[0])) *
        100
      );
    };
  }

  /**
   * 👂 Listener used in order to determine the selected chart filter mode
   */
  @Watch("selectedFilter")
  private onDaysFilterChanged(val: number, oldVal?: number) {
    switch (val) {
      case 0: {
        this.selectedFilterInDays = 1;
        break;
      }
      case 1: {
        this.selectedFilterInDays = 7;
        break;
      }
      case 2: {
        this.selectedFilterInDays = 30;
        break;
      }
      case 3: {
        this.selectedFilterInDays = 90;
        break;
      }
      case 4: {
        this.selectedFilterInDays = 365;
        break;
      }
      case 5: {
        this.selectedFilterInDays = -1;
        break;
      }
    }
    this.fetchAnalytics();
  }

  @Watch("selectedSubscriptionType")
  private onSelectedSubscriptionTypeChanged(val: string, oldVal: string) {
    this.fetchAnalytics();
  }

  /**
   * 👂 Listener for changes in the fetched analytics data
   */
  @Watch("originalAnalyticsData", { immediate: true })
  onOriginalAnalyticsChanged(
    val: Map<number, Analytics>,
    oldVal?: Map<number, Analytics>
  ) {
    const allAnalyticsEntries: Analytics[] = Object.values(val);

    // Check if there is any data in the object
    if (allAnalyticsEntries.length > 0) {
      this.availableAIStrategies = Object.keys(
        allAnalyticsEntries[0].total_active_bots
      );
      this.availableAIStrategies.unshift("All");

      // Prevent from resetting the previously selected strategy
      if (this.selectedAIStrategy == null) {
        this.selectedAIStrategy = this.availableAIStrategies[0];
      } else {
        this.onSelectedAIStrategyChanged(this.selectedAIStrategy);
      }
      // Reset the charts + dropdown for selected ai strategies
    } else {
      this.selectedAIStrategy = null;
      this.availableAIStrategies = [];
    }
  }

  /**
   * 👂 Listener for changes in the currently selected AI strategy
   */
  @Watch("selectedAIStrategy")
  onSelectedAIStrategyChanged(val: string, oldVal?: string) {
    // Get all date labels from the dataset
    this.dateLabels = Object.keys(this.originalAnalyticsData).map(
      (value: string, index: number, array: string[]) => {
        return new Date(
          Number(value) * 1000 - new Date().getTimezoneOffset() * 60
        );
      }
    );

    const analyticsEntries = Object.values(
      this.originalAnalyticsData
    ) as Analytics[];

    // Reset all values
    this.balanceInUseDataset = [];
    this.averageBotBalanceDataSet = [];
    this.totalConnectedExchangesDataSet = [];
    this.activeBotsDataset = [];
    this.tradesMadeDataset = [];
    this.totalUsersDataset = [];
    this.tradeVolumeDataset = new Map();
    this.averageActiveBotsPerAccount = new Map();
    this.averageBalanceInUsePerAccount = new Map();

    Object.entries(this.originalAnalyticsData).forEach(([date, entry]) => {
      let formattedDate = new Date(
        Number(date) * 1000 - new Date().getTimezoneOffset() * 60
      );

      // If "All" are selected combine all the results
      if (val === "All") {
        this.totalConnectedExchangesDataSet.push(entry.total_exchanges);

        this.totalUsersDataset.push(entry.total_users);

        this.activeBotsDataset.push(
          this.combineMapValues(Object.values(entry.total_active_bots))
        );

        this.balanceInUseDataset.push(
          this.combineMapValues(Object.values(entry.total_balance))
        );

        this.averageBotBalanceDataSet.push(
          this.combineMapValues(Object.values(entry.avg_balance_bot)) /
            Object.values(entry.avg_balance_bot).length
        );

        if (entry.trade_volume) {
          this.tradeVolumeDataset.set(
            formattedDate,
            this.combineMapValues(Object.values(entry.trade_volume))
          );
        }

        if (entry.avg_active_bot_user) {
          this.averageActiveBotsPerAccount.set(
            formattedDate,
            this.combineMapValues(Object.values(entry.avg_active_bot_user))
          );
        }

        if (entry.avg_balance_user) {
          this.averageBalanceInUsePerAccount.set(
            formattedDate,
            this.combineMapValues(Object.values(entry.avg_balance_user))
          );
        }

        if (entry.trades_made_1d) {
          let tempTradesMade = { buy: 0, sell: 0 };
          Object.values(entry.trades_made_1d).forEach((tradesEntry: any) => {
            if (tradesEntry.buy != null && tradesEntry.buy != undefined) {
              tempTradesMade.buy += tradesEntry.buy;
            }
            if (tradesEntry.sell != null && tradesEntry.sell != undefined) {
              tempTradesMade.sell += tradesEntry.sell;
            }
          });
          this.tradesMadeDataset.push(tempTradesMade);
        }
      } else {
        // Handle individual AI strategy results
        this.totalConnectedExchangesDataSet.push(entry.total_exchanges);
        this.totalUsersDataset.push(entry.total_users);
        this.activeBotsDataset.push((entry.total_active_bots as any)[val]);
        this.balanceInUseDataset.push((entry.total_balance as any)[val]);
        this.averageBotBalanceDataSet.push((entry.avg_balance_bot as any)[val]);
        if (entry.trade_volume) {
          this.tradeVolumeDataset.set(
            formattedDate,
            (entry.trade_volume as any)[val]
          );
        }
        if (entry.trades_made_1d) {
          this.tradesMadeDataset.push((entry.trades_made_1d as any)[val]);
        }
        if (entry.avg_active_bot_user) {
          this.averageActiveBotsPerAccount.set(
            formattedDate,
            (entry.avg_active_bot_user as any)[val]
          );
        }
        if (entry.avg_balance_user) {
          this.averageBalanceInUsePerAccount.set(
            formattedDate,
            (entry.avg_balance_user as any)[val]
          );
        }
      }
    });
  }

  mounted() {
    this.$store.dispatch("fetchSubscriptionConfiguration");
  }

  /**
   * 🔨 Combines all the numbers of an array into a single number
   */
  private combineMapValues(values: number[]): number {
    var count = 0;
    values.forEach((value: number) => {
      count += value;
    });
    return count;
  }

  /**
   * 🚀 Fetches the analytics from the API
   */
  private async fetchAnalytics() {
    this.isLoadingNewData = true;
    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/analytics", {
        days:
          this.selectedFilterInDays != -1 ? this.selectedFilterInDays : null,
        subscription:
          this.selectedSubscriptionType !== "All"
            ? this.selectedSubscriptionType
            : null
      })
      .then((response: any) => {
        if (Object.entries(response.data.values).length == 0) {
          this.originalAnalyticsData = new Map();
          this.onOriginalAnalyticsChanged(new Map());
        } else {
          this.originalAnalyticsData = response.data.values;
        }
      })
      .finally(() => {
        this.isLoadingNewData = false;

        // DEBUG CODE ONLY
        // this.originalAnalyticsData = new Map([
        //   [1611076482, Analytics.example],
        //   [1610076482, Analytics.example]
        // ]);
      });
  }
}
