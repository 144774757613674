/**
 * 📄 Model representing a signle admin role and its permissions
 */
export default class AdminRoleWithPermissions {
  constructor(
    public role: string,
    public permissions: any
  ) { }

  public static examples: AdminRoleWithPermissions[] = [
    new AdminRoleWithPermissions("Customer Support", {
      analytics: ['view'],
      bots: [],
      logs: [],
      models: [],
      stats: [],
      subscriptions: [],
      superadmin: [],
      users: []
    }),
    new AdminRoleWithPermissions("Super Admin", {
      analytics: ['view'],
      bots: [],
      logs: [],
      models: [],
      stats: [],
      subscriptions: [],
      superadmin: [],
      users: ['create', 'edit', 'delete']
    }),
  ]
}