


























import AdminRoleWithPermissions from "@/utility/admin-users/adminRoleWithPermissions";
import Vue from "vue";
import { Prop, Component, PropSync, Watch } from "vue-property-decorator";

@Component
export default class EditAdminRoleDialog extends Vue {
  // 📦 The initial version of the admin role with permissions
  @Prop({ default: undefined })
  public adminRoleWithPermissions!: AdminRoleWithPermissions;

  // 🚩 Flag for whether the dialog is shown
  @PropSync("showDialog", { type: Boolean })
  public show!: boolean;

  // 📦 The modified admin role permissions
  private adminRolePermissions: string = "";

  /**
   * 👂 Listener for changes in the passed admin role model
   */
  @Watch("adminRoleWithPermissions", {immediate: true})
  private onInitialRoleWithPermissionsChanged(
    val: AdminRoleWithPermissions,
    oldVal: AdminRoleWithPermissions
  ) {
    this.adminRolePermissions = JSON.stringify(val.permissions, null, "    ");
  }

  /**
   * 🔨 Updates the currently editted admin role's permissions
   */
  private async updateAdminRolePermissions() {
    await this.$store.dispatch(
      "updateAdminRolePermissions",
      new AdminRoleWithPermissions(
        this.adminRoleWithPermissions.role,
        JSON.parse(this.adminRolePermissions)
      )
    );
    this.show = false;
  }
}
