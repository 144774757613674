

























import Base from "../Base.vue";
import { Component } from "vue-property-decorator";
import AdminUsers from "./AdminUsers.vue";
import AdminRoles from "./AdminRoles.vue";
import AdminLoginLogs from "./AdminLoginLogs.vue";

@Component({
  components: { AdminUsers, AdminRoles, AdminLoginLogs }
})
export default class AdminAndRolesWrapper extends Base {
  protected async mounted() {
    super.mounted();
    this.$store.dispatch("fetchAdminUsers");
    this.$store.dispatch("fetchAdminRoles");
  }
}
