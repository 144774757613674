




















import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import SubscriptionTiers from "./subscription-tiers/SubscriptionTiers.vue";
import OBTHoldingTiers from "./obt-holding-tiers/OBTHoldingTiers.vue";
import NFTTiers from "./nft-tiers/NFTTiers.vue";

@Component({
  components: { SubscriptionTiers, OBTHoldingTiers, NFTTiers }
})
export default class UsersBase extends Base {
  protected async mounted() {
    super.mounted();
  }
}
