



































































































































































import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import camelcaseKeys from "camelcase-keys";
import Chart from "chart.js";

@Component
export default class OBvsTopMarketsChart extends Vue {
  // 📃 Possible time filters for the chart
  private chartFilters = {
    Day: 1,
    Week: 7,
    Month: 30,
    Quarter: 90,
    "Since Start": -1
  };

  // 📦 The selected filter for days
  private selectedFilterInDays: number = -1;

  private timePeriods = [
    { text: "Last 24 Hours", value: 24 * 3600 },
    { text: "Last 7 Days", value: 7 * (24 * 3600) },
    { text: "Last 30 Days", value: 30 * (24 * 3600) },
    { text: "Last 90 Days", value: 90 * (24 * 3600) },
    { text: "Last 180 Days", value: 180 * (24 * 3600) },
    { text: "Since Start", value: 0 },
    { text: "Custom", value: -1 }
  ];

  private selectedTimePeriod: number = this.timePeriods[
    this.timePeriods.length - 2
  ].value;

  private customSelectedDate: Date[] | null = null;

  private get customStartTime(): number | null {
    if (!this.customSelectedDate![0]) {
      return null;
    }
    let date = new Date(this.customSelectedDate![0]);
    date.setHours(0, 0, 0, 0);
    return (date.getTime() - new Date().getTimezoneOffset() * 60000) / 1000;
  }

  private get customEndTime(): number {
    let date = new Date(this.customSelectedDate![1]);
    date.setHours(23, 59, 59, 59);
    return (
      (new Date(date).getTime() - new Date().getTimezoneOffset() * 60000) / 1000
    );
  }

  private selectedMinimumDuration: number = 7;

  private dataAggregationTypes = ["day", "hour"];

  private selectedDataAggregationType: string = this.dataAggregationTypes[0];

  private availableMarketCryptocurrencies: string[] = [];

  private selectedMarketCryptocurrencies: string[] = ["BTC", "ETH"];

  private availableBotCryptocurrencies: string[] = [];

  private selectedBotCryptocurrencies: string[] | null = null;

  private weightedData: boolean = false;

  private availableAIStrategies: any[] = [];

  private selectedAIStrategies: string[] = [];

  // 📦 Dates for each portfolio check-up
  public dateLabels!: Date[];

  // 📦📈 Chart which displays the bot balance over time
  private obVsTopMarketsChart: Chart | null = null;

  // 📦 Dataset for OB's overall performance
  private obBotsRoiDataset: number[] = [];

  // 📦 Dataset for BTC's overall performance
  private btcDataset: number[] = [];

  // 📦 Dataset for ETH's overall performance
  private ethDataset: number[] = [];

  // 🚩 Flag for whether the chart's data is loading
  private isLoading: boolean = false;

  protected async mounted() {
    this.isLoading = false;
    await Promise.all([
      this.fetchAvailableAIStrategies(),
      this.fetchAvailableMarkets()
    ]);
    // this.fetchDataset();
  }

  private async fetchAvailableAIStrategies() {
    let response: any = await Vue.prototype.$api.get(
      "/api/proxy",
      "/api/v1/admin/strategies"
    );

    this.availableAIStrategies = response.data
      .map((strategy: any) => strategy.title)
      .sort();
  }

  private async fetchAvailableMarkets() {
    let response: any = await Vue.prototype.$api.get(
      "/api/proxy",
      "/api/v1/admin/exchangemarket"
    );

    let allTokens = [
      ...new Set(
        Object.values(response.data)
          .map((raw: any) => raw.pairs)
          .flat()
          .map((market: string) => market.split(":")[0])
          .sort()
      )
    ];

    this.availableMarketCryptocurrencies = allTokens;
    this.availableBotCryptocurrencies = allTokens;
  }

  private get formattedStartTime(): number | null {
    switch (this.selectedTimePeriod) {
      case 0:
        return null;
      case -1:
        return this.customStartTime;
      default:
        return this.selectedTimePeriod;
    }
  }

  private get formattedEndTime(): number | null {
    switch (this.selectedTimePeriod) {
      case -1:
        return this.customEndTime;
      default:
        return null;
    }
  }

  /**
   * 🚀 Fetches the dataset from the API
   */
  private fetchDataset() {
    this.isLoading = true;

    let parameters: any = {
      weighted: this.weightedData,
      markets: this.selectedMarketCryptocurrencies,
      strategies: this.selectedAIStrategies,
      min_duration: Number(this.selectedMinimumDuration) * 24 * 60 * 60,
      period: this.selectedDataAggregationType,
      start_time: this.formattedStartTime,
      end_time: this.formattedEndTime
    };

    if (this.selectedBotCryptocurrencies != null) {
      parameters.bot_markets = this.selectedBotCryptocurrencies;
    }

    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/botvsmarket", parameters)
      .then((response: any) => camelcaseKeys(response.data, { deep: true }))
      .then((response: any) => {
        this.dateLabels = Object.keys(response).map(
          (value: String, index: Number, array: String[]) => {
            return new Date(
              Number(value) * 1000 - new Date().getTimezoneOffset() * 60
            );
          }
        );
        this.obBotsRoiDataset = Object.values(response).map(
          (datasetEntry: any) => datasetEntry.botRoi
        );
        this.btcDataset = Object.values(response).map(
          (datasetEntry: any) => datasetEntry.btc
        );
        this.ethDataset = Object.values(response).map(
          (datasetEntry: any) => datasetEntry.eth
        );
        this.createChart();
      })
      .finally(() => (this.isLoading = false));
  }

  /**
   * 🎨 Draws a dynamic chart on the canvas which is responsible for displaying the portfolio
   */
  private createChart() {
    Chart.defaults.global.defaultFontFamily = "Aeroport";
    Chart.defaults.global.defaultFontColor = this.$vuetify.theme.currentTheme
      .chartLabel as string;

    if (this.obVsTopMarketsChart == null) {
      let container: any = document.getElementById(
        "container-ob-vs-market-chart"
      );
      this.obVsTopMarketsChart = new Chart(container, {
        type: "line",
        data: {
          labels: this.dateLabels,
          datasets: this.formatChartDatasets()
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          hover: {
            intersect: false
          },
          scales: {
            xAxes: this.getTimeScale(),
            yAxes: this.getYAxes()
          },
          legend: {
            display: true,
            position: "top",
            labels: {
              boxWidth: 6,
              usePointStyle: true
            }
          },
          tooltips: {
            intersect: false,
            mode: "index",
            callbacks: {
              label: (tooltipItem: any, data: any) => {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  ": " +
                  tooltipItem.yLabel.toFixed(2) +
                  "%"
                );
              }
            }
          }
        }
      });
    } else {
      this.obVsTopMarketsChart.data.datasets = this.formatChartDatasets();
      this.obVsTopMarketsChart.data.labels = this.dateLabels;
      this.obVsTopMarketsChart.config.options!!.scales!!.yAxes = this.getYAxes();
      this.obVsTopMarketsChart.options.scales!!.xAxes = this.getTimeScale();
      this.obVsTopMarketsChart.update();
    }
  }

  /**
   * 🏗 Configures the format and properties for X-axis of the chart
   */
  private getTimeScale(): any {
    return [
      {
        type: "time",
        time: {
          tooltipFormat: "DD/MM/YYYY HH:mm:ss",
          unit: "day",
          displayFormats: {
            day: "D MMM"
          }
        }
      }
    ];
  }

  /**
   * 🏗 Assembles the datasets that will be displayed
   */
  private formatChartDatasets(): any {
    return [
      {
        label: "One Click Crypto",
        data: this.obBotsRoiDataset,
        borderColor: "#353D74",
        backgroundColor: "#353D74",
        fill: false,
        pointRadius: 0,
        pointHoverRadius: 5,
        borderWidth: 3,
        tension: 0
      },
      {
        label: "BTC",
        data: this.btcDataset,
        borderColor: "#E8B844",
        backgroundColor: "#E8B844",
        fill: false,
        pointRadius: 0,
        pointHoverRadius: 5,
        borderWidth: 2.5,
        tension: 0
      },
      {
        label: "ETH",
        data: this.ethDataset,
        borderColor: "#8087B1",
        backgroundColor: "#8087B1",
        fill: false,
        pointRadius: 0,
        pointHoverRadius: 5,
        borderWidth: 2.5,
        tension: 0
      }
    ];
  }

  /**
   * 🔨 Calculates a dynamic y axis based on the provided dataset
   */
  private getYAxes(): any {
    return [
      {
        ticks: {
          callback: (value: number, index: number, values: number[]) => {
            return value.toFixed(2) + "%";
          }
        },
        grid: {
          color: this.$vuetify.theme.currentTheme.chartGridColor,
          borderColor: this.$vuetify.theme.currentTheme.chartGridColor
        },
        gridLines: {
          zeroLineColor: this.$vuetify.theme.currentTheme.chartGridColor
        }
      }
    ];
  }
}
