

























import Vue from "vue";
import { Prop, Component, PropSync, Watch } from "vue-property-decorator";
import SubscriptionTiers from "./SubscriptionTiers.vue";

@Component
export default class EditRawSubscriptionPlansDialog extends Vue {
  // 📦 The initial version of the subscription plans
  @Prop({ default: undefined })
  public plans!: SubscriptionTiers[];

  // 🚩 Flag for whether the dialog is shown
  @PropSync("showDialog", { type: Boolean })
  public show!: boolean;

  // 📦 The modified plans
  private plansModified: string = "";

  // 🚩 Flag for whether the new plans are being saved on the API
  private isSavingSubscriptionPlans: boolean = false;

  /**
   * 👂 Listener for changes in the passed subscription plans
   */
  @Watch("plans", { immediate: true })
  private onInitialRoleWithPermissionsChanged(
    val: SubscriptionTiers[],
    oldVal: SubscriptionTiers[]
  ) {
    this.plansModified = JSON.stringify(val, null, "    ");
  }

  /**
   * 🔨 Updates the currently editted admin role's permissions
   */
  private async saveSubscriptionPlanChanges() {
    try {
      this.isSavingSubscriptionPlans = true;
       await this.$store.dispatch(
        "saveSubscriptionPlans",
        JSON.parse(this.plansModified) as SubscriptionTiers[]
      );
      this.show = false;
    } finally {
      this.isSavingSubscriptionPlans = false;
    }
  }
}
