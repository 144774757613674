





































































































import Base from "../Base.vue";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import AddNewMarketDialog from "./AddNewMarketDialog.vue";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";

class ExchangeMarkets {
  constructor(
    public exchangeName: string,
    public numberOfMarkets: number,
    public marketsPerBaseCurrency: Map<string, string[]>
  ) {}
}

@Component({
  components: {
    AddNewMarketDialog,
    ConfirmationDialog
  },
  mixins: [PermissionCheckerMixin]
})
export default class Markets extends Base {
  // 📦 All the available markets divided per exchange
  private exchangesWithMarkets: ExchangeMarkets[] | null = null;

  // 📦 The selected exchange to which a new market should be added
  private selectedExchange: string | null = null;

  // 📦 The selected market the user is aiming to delete
  private selectedMarketToDelete: string | null = null;

  // 🚩 Flag for whether a confirmation dialog should be shown before removing a market
  private showRemoveMarketConfirmation: boolean = false;

  // 🚩 Flag for whether the dialog for adding a new market is shown
  private addNewMarketDialog: boolean = false;

  // 🚩 Flag for whether the markets per exchange are loading
  private isLoading: boolean = false;

  protected async mounted() {
    super.mounted();
    this.fetchMarketsPerExchange();
  }

  /**
   * 🏗 Converts a markets array to a map which uses the base currency as key
   */
  private getMarketsPerBaseCurrency(pairs: string[]) {
    pairs = pairs.sort();
    let marketsPerBaseCurrency = new Map<string, string[]>();

    let baseCurrencies = [
      ...new Set(pairs.map(market => market.split(":")[1]))
    ];

    baseCurrencies.forEach((base: string) => {
      marketsPerBaseCurrency.set(
        base,
        pairs.filter((market: string) => market.split(":")[1] === base)
      );
    });

    return marketsPerBaseCurrency;
  }

  /**
   * 🚀 Fetches all the available logs for the current environment
   */
  private fetchMarketsPerExchange() {
    this.isLoading = true;
    Vue.prototype.$api
      .get("/api/proxy", "/api/v1/admin/exchangemarket")
      .then((response: any) => {
        this.exchangesWithMarkets = Object.entries(response.data).map(
          ([exchange, values]) =>
            new ExchangeMarkets(
              exchange,
              (values as any).pairs.length,
              this.getMarketsPerBaseCurrency((values as any).pairs)
            )
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * 🚀 Adds a new market to a particular exchange
   */
  private addNewExchangeMarket(dataWrapper: any) {
    if (
      dataWrapper.market == null ||
      (dataWrapper.market as string).trim() == ""
    ) {
      this.showErrorSnackbar("Please provide a valid market");
      return;
    }

    Vue.prototype.$api
      .put("/api/proxy", "/api/v1/admin/exchangemarket", {
        [dataWrapper.exchange]: {
          pairs: [dataWrapper.market]
        }
      })
      .then((response: any) => {
        this.showSuccessSnackbar(
          "${market} was successfully added to ${exchange}!"
        );
        this.fetchMarketsPerExchange();
      });
  }

  /**
   * 🚀 Removes a market from a particular exchange
   */
  private removeExchangeMarket(exchange: string, market: string) {
    Vue.prototype.$api
      .delete("/api/proxy", "/api/v1/admin/exchangemarket", {
        [exchange]: {
          pairs: [market]
        }
      })
      .then((response: any) => {
        this.showSuccessSnackbar(
          "${market} was successfully removed from ${exchange}!"
        );
        this.fetchMarketsPerExchange();
      });
  }
}
