










































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";

interface ResourceStats {
  [resource: string]: any;
}

@Component
export default class Stats extends Base {
  private stats: ResourceStats[] = [];

  protected async mounted() {
    await super.mounted();
    this.fetchStrats();
  }

  private async fetchStrats() {
    Vue.prototype.$api
      .get("/api/proxy", "/api/v1/admin/stats")
      .then((response: any) => (this.stats = response.data));
  }
}
