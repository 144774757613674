

























import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import Bots from "./Bots.vue";
import BotsInactive from "./BotsInactive.vue";
import BotPortfolios from "./BotPortfolios.vue";

@Component({ components: { Bots, BotsInactive, BotPortfolios } })
export default class BotsBase extends Base {
  protected async mounted() {
    await super.mounted();
  }
}
