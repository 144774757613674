import { isPageAllowedForUser } from "./permissionChecker";
import {
  showErrorSnackbar,
} from "@/utils/snackbarUtils";

export const PAGES = {
  USERS: {
    route: "/users",
    permissionPage: "users"
  },
  BOTS: {
    route: "/bots",
    permissionPage: "bots"
  },
  STATS: {
    route: "/stats",
    permissionPage: "stats"
  },
  models: {
    route: "/strategies",
    permissionPage: "models"
  },
  ANALYTICS: {
    route: "/analytics",
    permissionPage: "analytics"
  },
  SUBSCRIPTION_TIERS: {
    route: "/subscription-tiers",
    permissionPage: "subscriptions"
  },
  LOGS: {
    route: "/logs",
    permissionPage: "logs",
    specificPermissionToEnablePage: "tree"
  },
  ADMIN_USERS:
  {
    route: "/admin-users",
    permissionPage: "superadmin"
  }
}

/**
 * 🔨 Allows to get the first available route for the current user based on permissions
 */
export function getFirstAvailableUrlForUser(): string {
  let firstAvailablePage: string | null = null;
  Object.values(PAGES).forEach((permission: any) => {
    if (
      isPageAllowedForUser(
        permission.permissionPage,
        permission.specificPermissionToEnablePage ? permission.specificPermissionToEnablePage : "view"
      ) && !firstAvailablePage
    ) {
      firstAvailablePage = permission.route;
    }
  });
  if (firstAvailablePage) {
    return firstAvailablePage;
  } else {
    showErrorSnackbar("Your account has been blocked. Please contact a super admin about it.")
    return "/login";
  }
}