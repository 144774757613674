
import store from '@/store';

/**
* 📡 Sends an error event that will be displayed as snackbar
* @param message - the error message that needs be shown
*/
export function showErrorSnackbar(message: string) {
  store.dispatch("publishSnackbarErrorEvent", message);
}

/**
* 📡 Sends a success event that will be displayed as snackbar
* @param message - the success message that needs to be shown
*/
export function showSuccessSnackbar(message: string) {
  store.dispatch("publishSnackbarSuccessEvent", message);
}

/**
 * 📡 Sends an information event that will be displayed as snackbar
 * @param message - the error message that needs be shown
 */
export function showInformationSnackbar(message: string) {
  store.dispatch("publishSnackbarInformationEvent", message);
}