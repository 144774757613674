





































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import AdminRoleWithPermissions from "@/utility/admin-users/adminRoleWithPermissions";
import { getFirstAvailableUrlForUser } from "@/utils/routerUtils";
import VueRecaptcha from "vue-recaptcha";

@Component({
  components: { VueRecaptcha }
})
export default class Login extends Base {
  private email: string = "";
  private emailRules = [
    (v: string) =>
      (!!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
      "Please provide a valid E-mail address"
  ];

  private password: string | null = "";
  private passwordVisible: boolean = false;

  private isRecaptchaValid: boolean = false;

  private isLoggingIn: boolean = false;

  private get recaptchaSiteKey(): string {
    return process.env.VUE_APP_RECAPTCHA;
  }

  protected async mounted() {
    await super.mounted();
  }

  private async login() {
    if (!this.isRecaptchaValid || this.isLoggingIn) {
      return;
    }

    this.isLoggingIn = true;

    setTimeout(() => {
      Vue.prototype.$api
        .post("/api/login", null, {
          username: this.email,
          password: this.password
        })
        .then((response: any) => {
          this.$store.commit(
            "updateCurrentUserRoleAndPermissions",
            new AdminRoleWithPermissions(
              response.data.role,
              response.data.permissions
            )
          );
          this.$store.commit("setCurrentUser", response.data.username);
          const token = response.data.token;
          this.$store.commit("updateAuthorizationToken", token);
          Vue.axios.defaults.headers.common["Authorization"] = token;
          this.$router.push(getFirstAvailableUrlForUser());
        })
        .finally(() => {
          (this.email = ""), (this.password = null);
          this.isRecaptchaValid = false;
          this.isLoggingIn = false;
        });
    }, 2000);
  }

  private handleCaptchaVerify() {
    this.isRecaptchaValid = true;
  }

  private handleCaptchaError() {
    this.showErrorSnackbar("Recaptcha is incorrect");
  }

  private handleCaptchaExpired() {
    this.showErrorSnackbar("Recaptcha has expired. Please refresh the page");
  }
}
