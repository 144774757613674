import Bot from "./bot";
import Referral from "./referral";
import { OTPDetails } from "./user/otpDetails";
import { UserTokenDetails } from "./user/userTokenDetails";
import { WalletTransaction } from "./wallet/walletTransaction";

export enum SubscriptionType {
  Free = "Free",
  FreePro = "Free Pro",
  Paid = "Paid"
}

export enum UserBotTierRank {
  "Regular (1)" = 1,
  "Early Tester (2)" = 2,
  "VIP (3)" = 3
}

export default class User {
  constructor(
    public email: string,
    public name: string,
    public exchanges: string[],
    public bots_count: number,
    public bots: Bot[],
    public status: boolean,
    public bot_balance: number,
    public balance: { [exchange: string]: number },
    public time_added: number,
    public last_active: number,
    public subscription: SubscriptionType,
    public referrals: Referral[],
    public token: UserTokenDetails | any,
    public token_transactions: WalletTransaction[],
    public available_token_balance: number,
    public OTP: OTPDetails | any,
    public number_of_NFTs: number,
    public botTierRank: UserBotTierRank
  ) { }

  public getBotTierRank(): string {
    return UserBotTierRank[this.botTierRank]
  }

  public getBotTiers(): (string | UserBotTierRank)[] {
    return Object.keys(UserBotTierRank).filter((tier: any) => isNaN(tier))
  }

  public static example: User = new User(
    "test@onebutton.trade",
    "Test",
    [],
    0,
    [],
    true,
    0,
    {},
    1633972652,
    454419,
    SubscriptionType.Free,
    [],
    {},
    [],
    0,
    {},
    1,
    UserBotTierRank["Regular (1)"]
  );
}
