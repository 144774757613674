var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"header-color":"primary","headers":_vm.openOrdersHeaders,"items":_vm.openOrders ? _vm.openOrders : [],"options":_vm.paginationOpenOrders,"custom-sort":_vm.sortByCreateTime,"loading":_vm.loading,"loading-text":"Loading open orders..."},on:{"update:options":function($event){_vm.paginationOpenOrders=$event}},scopedSlots:_vm._u([{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateFromAPI(item.createTime)))])]}},{key:"item.expireTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateFromAPI(item.expireTime)))])]}},{key:"item.side",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getTypeColor(item.side)},[_vm._v(_vm._s(item.side))])]}},{key:"item.volume",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volume)+" "+_vm._s(item.currency))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDisplayNumber(item.price))+" "+_vm._s(item.priceCurrency))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }