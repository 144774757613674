



















































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import ServerSelector from "@/components/ServerSelectorGeneric.vue";
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";

interface Strategy {
  title: string;
  enabled: boolean;
  name: string;
  strategy_image: string;
  strategy_description: string;
  proto_params: any;
  descriptions: any;
  display_names: any;
  param_types: any;
  status: StrategyStatus;
  markets?: string[];
  candles?: string;
}

export enum StrategyStatus {
  NORMAL = "normal",
  FEATURED = "featured",
  NEW = "new",
  PREMIUM = "premium",
  LEGACY = "legacy"
}

@Component({
  components: { ServerSelector },
  mixins: [PermissionCheckerMixin]
})
export default class Strategies extends Base {
  private dialog = false;
  private edit_dialog = false;
  private migrate_dialog = false;
  private confirm_delete = false;

  private strat_json: string = "";
  private strat_selected: string = "";
  private promotionalStatusesByPriority: string = "";

  private strat_name?: string;
  private strat_in?: any;
  private model_in?: any;
  private bundle_in?: any;
  private migrate_serverid?: string;

  private strategies: Strategy[] = [];
  private headers = [
    { text: "Display Name", value: "title" },
    { text: "Strategy", value: "name" },
    { text: "Image", value: "strategy_image" },
    { text: "Candles", value: "candles" },
    { text: "Markets", value: "markets" },
    { text: "Status", value: "status" },
    { text: "Actions", value: "action", sortable: false }
  ];

  // 🚩 Flag for whether the invitations are loading
  private isLoading: boolean = false;

  protected async mounted() {
    await super.mounted();
    this.fetchStrats();
    this.fetchPromotionalStatusesOrder();
  }

  /**
   * 🚀 Fetches the promotional statuses' order from the API
   */
  private async fetchPromotionalStatusesOrder() {
    const response = await Vue.prototype.$api.get(
      "/api/proxy",
      "/api/v1/admin/promotingorder"
    );
    this.promotionalStatusesByPriority = JSON.stringify(response.data.promoting_order);
  }

  /**
   * 🚀 Updates the promotional statuses' order on the API
   */
  private updatePromotionalStatusesOrder() {
    Vue.prototype.$api.post(
      "/api/proxy",
      "/api/v1/admin/promotingorder", {
        promoting_order: JSON.parse(this.promotionalStatusesByPriority)
      }
    ).then((response: any) => {
       super.showSuccessSnackbar("Promotional statuses order has been updated successfully");
    });
  }

  private onModelFile(ev: File) {
    this.model_in = ev;
  }

  private onStratFile(ev: File) {
    this.strat_in = ev;
  }

  private onBundleFile(ev: File) {
    this.bundle_in = ev;
  }

  private async startMigrate(item: Strategy) {
    this.strat_selected = item.name; // For future lookup at backend
    this.migrate_dialog = true;
  }

  private async migrateStrategy() {
    const ri = await Vue.axios.post(
      "/api/proxy",
      { uuid: this.strat_selected },
      {
        headers: {
          "X-Path-Forward": "/api/v1/admin/migratestrategy",
          "X-Server-Forward": this.$store.state.globalStateModule.selectedServer
        },
        responseType: "arraybuffer"
      }
    );
    const data = new FormData();
    data.append(
      "bundle",
      new Blob([ri.data], { type: "application/zip" }),
      "bundle.zip"
    );
    console.log("Finished downloading bundle from origin server");

    Vue.axios
      .post("/api/proxy", data, {
        headers: {
          "X-Path-Forward": "/api/v1/admin/migratestrategy",
          "X-Server-Forward": this.migrate_serverid
        }
      })
      .then(response => {
        super.showSuccessSnackbar("The strategy was successfully migrated");
      });
  }

  private async startDelete(item: Strategy) {
    this.strat_selected = item.name;
    this.confirm_delete = true;
  }

  private async endDelete() {
    Vue.prototype.$api
      .delete("/api/proxy", "/api/v1/admin/strategies", {
        uuid: this.strat_selected
      })
      .then((response: any) => {
        super.showSuccessSnackbar("The strategy was successfully deleted");
        this.fetchStrats();
      });
  }

  private async startEdit(item: Strategy) {
    this.strat_selected = item.name; // For future lookup at backend
    this.strat_json = JSON.stringify(item, null, " ");
    this.edit_dialog = true;
  }

  private async switchStratEnabled(item: Strategy) {
    Vue.prototype.$api
      .put("/api/proxy", "/api/v1/admin/strategies", {
        original_id: item.name,
        new_config: item
      })
      .then((response: any) => this.fetchStrats());
  }

  private async fetchStrats() {
    this.isLoading = true;
    Vue.prototype.$api
      .get("/api/proxy", "/api/v1/admin/strategies")
      .then((response: any) => {
        this.strategies = response.data.sort((strategy: Strategy) => !strategy.enabled);
      })
      .finally(() => (this.isLoading = false));
  }

  private async editStrategyPost() {
    const nconfig = JSON.parse(this.strat_json);

    Vue.prototype.$api
      .put("/api/proxy", "/api/v1/admin/strategies", {
        original_id: this.strat_selected,
        new_config: nconfig
      })
      .then((response: any) => {
        super.showSuccessSnackbar("Your changes were successfully saved");
        this.fetchStrats();
      });
  }

  private async addStrategyPost() {
    const data = new FormData();
    if (this.bundle_in)
    {
      data.append(
        "bundle",
        this.bundle_in
      );
      
      Vue.prototype.$api
        .post("/api/proxy", "/api/v1/admin/migratestrategy", data)
        .then((response: any) => {
          super.showSuccessSnackbar("The new strategy was added successfully");
          this.fetchStrats();
        });
    }
    else
    {
      if (!this.strat_in || !this.strat_name) return;

      data.append("name", this.strat_name);
      data.append("strategydefinition", this.strat_in, this.strat_in.name);
      if (this.model_in)
        data.append("modelfile", this.model_in, this.model_in.name);

      Vue.prototype.$api
        .post("/api/proxy", "/api/v1/admin/strategies", data)
        .then((response: any) => {
          super.showSuccessSnackbar("The new strategy was added successfully");
          this.fetchStrats();
        });
    }
  }
}
