
























import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import Vue from "vue"
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";

@Component({
  mixins: [PermissionCheckerMixin]
})
export default class BotPortfolios extends Base {
  // 📦 The JSON rendered for the bot packages
  private botPortfolios: string = "";

  // 🚩 Flag for whether the bot packages are being saved on the API
  private isSavingBotPortfolios: boolean = false;

  protected async mounted() {
    super.mounted();
    this.fetchBotPortfolios();
  }

  /**
   * 🚀 Fetches the bot packages from the API and formats it
   */
  private fetchBotPortfolios() {
    Vue.prototype.$api
      .get("/api/proxy", "/api/v1/admin/botpackages/config")
      .then((response: any) => {
        this.botPortfolios = JSON.stringify(response.data, null, "   ");
      });
  }

  /**
   * 🚀 Saves the updated bot packages on the API
   */
  private saveBotPortfolios() {
    this.isSavingBotPortfolios = true;
    Vue.prototype.$api
      .post(
        "/api/proxy",
        "/api/v1/admin/botpackages/config",
        JSON.parse(this.botPortfolios)
      )
      .then((response: any) => {
        this.showSuccessSnackbar(
          "Bot packages were successfully updated!"
        );
        this.fetchBotPortfolios();
      })
      .finally(() => (this.isSavingBotPortfolios = false));
  }
}
