





























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import TooltipLabel from "@/components/general/TooltipLabel.vue";
import DateFormatter from "@/mixins/dateFormatterMixin";
import PercentIndicator from "@/mixins/percentIndicatorMixin";
import NumberFormatter from "@/mixins/numberFormatter";
import TradeModel from "@/utility/bots/tradeLogEntry";
import { sortItems } from "@/utils/sortingUtils";

@Component({
  components: { TooltipLabel },
  mixins: [PercentIndicator, DateFormatter, NumberFormatter]
})
export default class TradeHistoryTable extends Vue {
  // 📦 All the trades the active bots have conducted
  @Prop({ default: [] })
  public tradeHistory?: TradeModel[];

  // 📦 Headers used for the trade history table
  public tradeHistoryHeaders = [
    { text: "Date Executed", value: "date" },
    { text: "Market", value: "market" },
    { text: "Side", value: "type" },
    { text: "Price", value: "price" },
    { text: "Amount", value: "amount" },
    { text: "Fee", value: "fee" },
    { text: "Ending Balance", value: "balance" }
  ];

  // 📦 Pagination object used for default sorting the trade log
  private paginationTradeLog = {
    sortBy: ["date"],
    sortDesc: [false],
    mustSort: true,
    itemsPerPage: 10
  };

  /**
   * 📋 Custom sorting function that orders the trades by date
   */
  private sortByDate(items: any[], index: string[], isDesc: boolean[]) {
    return sortItems("date", items, index, isDesc);
  }

  /**
   * 🎨 Sets the color of a trade line based on the type
   */
  private getTypeColor(type: string) {
    if (type == "BUY") return "green--text font-weight-bold";
    if (type == "SELL") return "red--text font-weight-bold";
  }
}
