




















































import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import ListBot from "@/utility/listBot";
import PercentIndicator from "@/mixins/percentIndicatorMixin";
import RefreshIndicator from "@/components/general/RefreshIndicator.vue";
import BotActivityStatus from "./components/BotActivityStatus.vue";

@Component({
  components: { RefreshIndicator, BotActivityStatus },
  mixins: [PercentIndicator]
})
export default class BotsInactive extends Base {
  // 📦 The id of the bot that is being searched
  private search: string = "";

  // 📦 All inactive bots
  private bots: ListBot[] = [];

  // 📦 Headers for the bots table
  private headers = [
    { text: "Id", value: "uid" },
    { text: "Status", value: "last_trade_attempt" },
    { text: "Strategy", value: "strat_name" },
    { text: "Exchange", value: "exchange" },
    { text: "Market", value: "market" },
    { text: "Owner", value: "user" },
    { text: "Balance", value: "current_balance", filterable: false },
    { text: "Activated", value: "activated", filterable: false },
    { text: "ROI", value: "roi", filterable: false },
    { text: "Trades made", value: "trades_made", filterable: false }
  ];
  // 📦 All cached inactive bots
  private get inactiveBots(): ListBot[] {
    return this.$store.state.bots.inactiveBots;
  }

  // 🚩 Flag for whether the inactive bots are being fetched
  private get areInactiveBotsFetched(): boolean {
    return this.$store.state.bots.areInactiveBotsFetched;
  }

  // 📦 Last time the active bots data has been refreshed
  private get lastDataRefresh(): Date | null {
    if (this.$store.state.bots.lastRefreshOfInactiveBots != null) {
      return new Date(this.$store.state.bots.lastRefreshOfInactiveBots);
    }
    return null;
  }

  protected async mounted() {
    await super.mounted();
  }

  /**
   * 👂 Opens bot logs when a row of the data table is clicked
   */
  private onRowClicked(bot: ListBot) {
    let routeData = this.$router.resolve({
      name: "BotLogs",
      params: { id: bot.uid }
    });
    window.open(routeData.href, "_blank");
  }
}
