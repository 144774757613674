









import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class TooltipLabel extends Vue {
  // 📦 The label that will display by default
  @Prop()
  public label!: string;

  // 📦 The tooltip body that will appear on hover
  @Prop()
  public tooltip!: string;
}
