




import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Chart, { PositionType } from "chart.js";
import mapCurrencyToColor from "@/utils/currencyColorMapper";
import Coin from "@/utility/bots/CoinHeld";

@Component
export default class BalanceInUseDonutChart extends Vue {
  // 📦 Unique ID that should be attached as component identifier in order for the chart to be rendered correctly
  @Prop()
  public chartId!: string;

  // 📦 Balance in use per coin that should be displayed on the chart
  @Prop()
  public balanceInUsePerCoin!: Coin[];

  // 📦 The hight of the chart
  @Prop()
  public height!: number;

  // 📦 The legend position around the chart
  @Prop({ default: "right" })
  public legendPosition!: PositionType;

  // 🚩 Flag for whether the chart should be denser
  @Prop({ default: false })
  public dense!: boolean;

  // 🚩 Flag for whether the tooltip for each section should have its content on multiple lines
  @Prop({ default: false })
  public multilineTooltip?: boolean;

  // 📦 The chart reference holder
  private chart: any;

  // 📦 The labels that would be used in the legend of the donut chart
  private get chartLegendLabels(): string[] {
    const keys = this.balanceInUsePerCoin.map(
      (coin: Coin) =>
        coin.name +
        ": " +
        coin.amount.toFixed(8) +
        (this.dense ? "" : " ($" + coin.value.toFixed(2) + ")")
    );
    return keys.length == 0 ? ["No Balance in Use"] : keys;
  }

  // 📦 The data that would be displayed inside the donut chart
  private get chartData(): number[] {
    const values = this.balanceInUsePerCoin.map((coin: Coin) => coin.value);
    return values.length == 0
      ? [0.001]
      : values.map((value: number) => Number(value.toFixed(2)));
  }

  // 👂 Listener for changes in the provided balance in use per coin
  @Watch("balanceInUsePerCoin", { immediate: true })
  private onBalanceInUsePerCoinChanged(val: Coin[], oldVal: Coin[]) {
    this.createChart();
  }

  mounted() {
    this.createChart();
  }

  // 📦 The background colors that would be used for each part of the donut chart
  private chartBackgroundColors(): string[] {
    const keys = this.balanceInUsePerCoin.map((coin: Coin) => coin.name);
    return keys.length == 0
      ? ["#ececec"]
      : keys.map((market: string) => mapCurrencyToColor(market));
  }

  /**
   * 🔨 Generates the chart options that are going to be used in order to display it
   */
  private getChartOptions(): any {
    return {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 65,
      legend: this.getLegend(),
      tooltips: {
        mode: "index",
        callbacks: {
          label: (tooltipItems: any, data: any) => {
            const index = tooltipItems.index;
            let labelText =
              data.labels[index].split(" ")[0] +
              ": " +
              this.balanceInUsePerCoin[index].amount;

            if (!this.multilineTooltip) {
              labelText +=
                " (" +
                this.balanceInUsePerCoin[index].percentageOfAll.toFixed(2) +
                "%)";
            }

            return labelText;
          },
          afterLabel: (tooltipItems: any, data: any) => {
            var index = tooltipItems.index;
            if (this.multilineTooltip) {
              return (
                "$" +
                this.balanceInUsePerCoin[index].value.toFixed(2) +
                " (" +
                this.balanceInUsePerCoin[index].percentageOfAll.toFixed(2) +
                "%)"
              );
            }
            return "";
          },
        },
      },
    };
  }

  /**
   * 🏗 The legend that should be displayed in the chart
   */
  private getLegend(): any {
    if (this.dense && this.$vuetify.breakpoint.lgAndDown) {
      return {
        display: true,
        position: this.legendPosition,
        labels: {
          boxWidth: 20,
          fontSize: 10,
        },
      };
    } else {
      return {
        display: true,
        position: this.legendPosition,
      };
    }
  }

  /**
   * 🎨 Draws a dynamic chart on the canvas which is responsible for displaying the balance in use
   */
  private createChart() {
    // Check if the new dataset is the same as the old one => if so don't do anything
    if (
      this.chart &&
      this.chart.data &&
      this.chart.data.datasets[0] //&&
      // _.isEqual(this.chart.data.datasets[0].data, this.chartData)
    ) {
      return;
    }

    if (this.chart == null) {
      let container: any = document.getElementById(this.chartId);
      this.chart = new Chart(container, {
        type: "doughnut",
        data: {
          labels: this.chartLegendLabels,
          datasets: [
            {
              data: this.chartData,
              backgroundColor: this.chartBackgroundColors(),
            },
          ],
        },
        options: this.getChartOptions(),
      });
    } else {
      this.chart.data.datasets = [
        {
          data: this.chartData,
          backgroundColor: this.chartBackgroundColors(),
        },
      ];
      this.chart.data.labels = this.chartLegendLabels;
      this.chart.update();
    }
  }
}
