/**
 * 🎨 The possible types of billings that a plan can have
 */
export enum SubscriptionTierBillingType {
  Free = "Free",
  Commission = "Commission",
  FixedPrice = "Fixed-Price",
  FeePerTrade = "Fee-Per-Trade",
  Undefined = "Undefined"
}

/**
 * 📄 Model which represents a single subscription available on the platform
 */
export default class SubscriptionTier {
  public billingType: SubscriptionTierBillingType;
  public formattedBenefits: string;

  constructor(
    public sub: string,
    public description: string,
    public allowedBots: number,
    public maxTotalInUse: number,
    public paymentFees: number,
    public pricePerMonth: number,
    public benefits: any,
    public priceIds: string[],
    public payments: boolean,
    public freePro: boolean,
    public preDate: number,
    public isHidden: boolean,
    public pctPerTrade: number,
    public minAmountTrade: number,
    public noticeDurationInSec: number
  ) {
    this.billingType = this.determineBillingType();
    this.formattedBenefits = JSON.stringify(this.benefits, null, " ");
  }

  /**
   * 🔨 Determines the most proper billing type base on the data provided for the subscription plan
   */
  private determineBillingType(): SubscriptionTierBillingType {
    if (this.pctPerTrade > 0) {
      return SubscriptionTierBillingType.FeePerTrade;
    }
    if (this.paymentFees == 0 && this.pricePerMonth == 0) {
      return SubscriptionTierBillingType.Free;
    }
    if (this.paymentFees > 0 && this.pricePerMonth == 0) {
      return SubscriptionTierBillingType.Commission;
    }
    if (this.paymentFees == 0 && this.pricePerMonth > 0) {
      return SubscriptionTierBillingType.FixedPrice;
    }
    return SubscriptionTierBillingType.Undefined;
  }

  /**
   * 🔨 Formats the payment fees to be presented as percentages
   */
  public get formattedPaymentFees(): number {
    return this.paymentFees * 100;
  }

  /**
   * 🔨 Converts the percentage represented payment fees into fraction
   */
  public set formattedPaymentFees(formattedPaymentFees: number) {
    this.paymentFees = formattedPaymentFees / 100;
  }

  /**
   * 🔨 Formats the price ids' array into a displayable string
   */
  public get formattedPriceIds(): string {
    return JSON.stringify(this.priceIds);
  }

  /**
   * 🔨 Converts the price ids' string into an araay
   */
  public set formattedPriceIds(rawPriceIds: string) {
    this.priceIds = JSON.parse(rawPriceIds);
  }
}
