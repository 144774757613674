





















































import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";

@Component
export default class ServerDialog extends Vue {
  @PropSync("showDialog", { type: Boolean })
  public show!: boolean;
  public loading = false;

  public host?: string;
  public name?: string;
  public key?: string;

  private async addServerBtn() {
    this.loading = true;

    let r = await Vue.axios.post("/api/servers", {
      key: this.key, host: this.host, name: this.name
    });

    this.loading = false;
    if (r.status < 400) {
      this.show = false;
    }
  }
}
