var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('v-data-table',{attrs:{"headers":_vm.adminUsersTableHeaders,"items":_vm.adminUsers,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [(_vm.isFunctionalityAllowedForUser('superadmin', 'modify'))?_c('v-select',{staticClass:"my-2",attrs:{"items":_vm.adminUserRoles,"flat":"","outlined":"","dense":"","hide-details":""},on:{"input":function($event){return _vm.$store.dispatch('updateAdminUser', item)}},model:{value:(item.role),callback:function ($$v) {_vm.$set(item, "role", $$v)},expression:"item.role"}}):_c('div',[_vm._v(" "+_vm._s(item.role)+" ")])]}},{key:"item.timeAdded",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDateFromAPI(item.timeAdded))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"d-flex":"","justify-end":""}},[(
            _vm.isFunctionalityAllowedForUser('superadmin', 'delete') &&
              item.username !== _vm.currentUser
          )?_c('v-btn',{attrs:{"small":"","color":"red"},on:{"click":function($event){_vm.selectedUserToDelete = item;
            _vm.showDeleteAdminUserDialog = true;}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}])}),_c('v-layout',[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.showCreateAdminUserDialog = true}}},[_vm._v(" Create New Admin User ")])],1),(_vm.selectedUserToDelete)?_c('ConfirmationDialog',{attrs:{"showDialog":_vm.showDeleteAdminUserDialog,"title":'Delete ' + _vm.selectedUserToDelete.username + '?',"message":'Are you sure you want to delete ' +
        _vm.selectedUserToDelete.username +
        '? This action cannot be reversed.'},on:{"update:showDialog":function($event){_vm.showDeleteAdminUserDialog=$event},"update:show-dialog":function($event){_vm.showDeleteAdminUserDialog=$event},"confirmationGiven":function($event){return _vm.$store.dispatch('deleteAdminUser', _vm.selectedUserToDelete.username)}}}):_vm._e(),_c('CreateNewAdminDialog',{attrs:{"showDialog":_vm.showCreateAdminUserDialog},on:{"update:showDialog":function($event){_vm.showCreateAdminUserDialog=$event},"update:show-dialog":function($event){_vm.showCreateAdminUserDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }