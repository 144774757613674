








































import Base from "../Base.vue";
import { Component } from "vue-property-decorator";
import Vue from "vue";
import AdminLoginLogs from "@/utility/admin-users/adminLoginLog";
import camelcaseKeys from "camelcase-keys";
import DateFormatter from "@/mixins/dateFormatterMixin";
import TooltipLabel from "@/components/general/TooltipLabel.vue";
import { sortItems } from "@/utils/sortingUtils";

@Component({
  components: { TooltipLabel },
  mixins: [DateFormatter]
})
export default class AdminRoles extends Base {
  // 📦 The headers that will be applied on the admin login logs table
  private adminLoginLogsHeaders = [
    { text: "Admin", value: "username" },
    { text: "IP", value: "ip" },
    { text: "Country", value: "country" },
    { text: "User Agent", value: "userAgent" },
    { text: "Date", value: "timestamp" }
  ];

  // 📦 The search term that the logs should be filtered by
  private searchedLog: string = "";

  // 📦 All the admin login logs
  private loginLogs: AdminLoginLogs[] = [];

  // 📦 Default sorting by timestamp
  private tableOptions = {
    sortBy: ["timestamp"],
    sortDesc: [false],
    mustSort: true,
    itemsPerPage: 15
  };

  protected async mounted() {
    super.mounted();
    this.fetchAdminLoginLogs();
  }

  /**
   * 🚀 Fetches the admin login logs from the API
   */
  private async fetchAdminLoginLogs() {
    const response = await Vue.prototype.$api.get("/api/logs");
    this.loginLogs = camelcaseKeys(response.data);
  }

  /**
   * 🔨 Custom sorting functionality used in the bot table
   */
  private sortByDate(items: any[], index: string[], isDesc: boolean[]) {
    return sortItems("timestamp", items, index, isDesc);
  }
}
