

































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DateFormatter from "@/mixins/dateFormatterMixin";
import OpenOrder from "@/utility/bots/openOrder";
import { sortItems } from "@/utils/sortingUtils";
import NumberFormatter from "@/mixins/numberFormatter";

@Component({
  mixins: [DateFormatter, NumberFormatter]
})
export default class OpenOrdersTable extends Vue {
  // 📦 All open orders the bots have
  @Prop({ default: [] })
  public openOrders?: OpenOrder[];

  // 📦 Headers used for the open orders table
  public openOrdersHeaders = [
    { text: "Created At", value: "createTime" },
    { text: "Expires In", value: "expireTime" },
    { text: "Market", value: "market" },
    { text: "Side", value: "side" },
    { text: "Type", value: "type" },
    { text: "Amount", value: "volume" },
    { text: "Price", value: "price" }
  ];

  // 📦 Pagination object used for default sorting the open orders
  private paginationOpenOrders = {
    sortBy: ["createTime"],
    sortDesc: [false],
    mustSort: true,
    itemsPerPage: 10
  };

  /**
   * 📋 Custom sorting function that orders the trades by date
   */
  private sortByCreateTime(items: any[], index: string[], isDesc: boolean[]) {
    return sortItems("createTime", items, index, isDesc);
  }

  /**
   * 🎨 Sets the color of a trade line based on the type
   */
  private getTypeColor(type: string) {
    if (type == "BUY") return "green--text font-weight-bold";
    if (type == "SELL") return "red--text font-weight-bold";
  }
}
