var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"ma-md-10",attrs:{"column":""}},[_c('v-layout',{attrs:{"d-flex":"","justify-start":""}},[_c('span',{staticClass:"title font-weight-bold"},[_vm._v("Markets")])]),(_vm.isLoading && !_vm.marketsByExchange)?_c('v-progress-circular',{staticClass:"mt-10",attrs:{"indeterminate":"","color":"primary","size":"56"}}):_c('v-layout',{attrs:{"column":""}},[_vm._l((_vm.exchangesWithMarkets),function(exchangeWithMarkets){return _c('v-layout',{key:exchangeWithMarkets,staticClass:"my-3 pa-3",staticStyle:{"border-style":"solid","border-color":"lightgray","border-width":"1px","border-radius":"4px"},attrs:{"d-flex":"","align-center":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(exchangeWithMarkets.exchangeName)+" ("+_vm._s(exchangeWithMarkets.numberOfMarkets)+") ")]),_c('v-layout',{attrs:{"column":""}},_vm._l((exchangeWithMarkets.marketsPerBaseCurrency),function(ref){
          var base = ref[0];
          var markets = ref[1];
return _c('v-layout',{key:base},[_c('v-layout',{staticClass:"ml-6 mt-2",attrs:{"column":"","d-flex":"","align-start":""}},[_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(base))]),_c('v-chip-group',{attrs:{"column":""}},_vm._l((markets),function(market){return _c('v-chip',{key:market,attrs:{"close":_vm.isFunctionalityAllowedForUser('markets', 'modify'),"small":"","outlined":"","color":"primary"},on:{"click:close":function($event){_vm.selectedMarketToDelete = market;
                  _vm.selectedExchange = exchangeWithMarkets.exchangeName;
                  _vm.showRemoveMarketConfirmation = true;}}},[_vm._v(" "+_vm._s(market)+" ")])}),1)],1)],1)}),1),_c('v-spacer'),(_vm.isFunctionalityAllowedForUser('markets', 'modify'))?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){_vm.addNewMarketDialog = true;
          _vm.selectedExchange = exchangeWithMarkets.exchangeName;}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add ")],1):_vm._e()],1)}),_c('AddNewMarketDialog',{attrs:{"showDialog":_vm.addNewMarketDialog,"exchange":_vm.selectedExchange},on:{"update:showDialog":function($event){_vm.addNewMarketDialog=$event},"update:show-dialog":function($event){_vm.addNewMarketDialog=$event},"new-market-details":_vm.addNewExchangeMarket}}),_c('ConfirmationDialog',{attrs:{"showDialog":_vm.showRemoveMarketConfirmation,"title":'Are you sure you want to remove ' +
          _vm.selectedMarketToDelete +
          ' from ' +
          _vm.selectedExchange +
          '?',"message":"By removing this market from the exchange new bots cannot be created on it. Furthermore, all bots running on it will 'hang' and won't execute any new trades."},on:{"update:showDialog":function($event){_vm.showRemoveMarketConfirmation=$event},"update:show-dialog":function($event){_vm.showRemoveMarketConfirmation=$event},"confirmationGiven":function($event){return _vm.removeExchangeMarket(_vm.selectedExchange, _vm.selectedMarketToDelete)}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }