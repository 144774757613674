










import Vue from "vue";
import Component from "vue-class-component";
import Navbar from "./components/Navbar.vue";
import GlobalSnackbar from "@/components/GlobalSnackbar.vue";

@Component({
  components: { Navbar, GlobalSnackbar }
})
export default class App extends Vue {
  private baseURL = window.location.origin;

  private created() {
    // In development mode use a different port
    if (this.baseURL.includes("localhost")) {
      this.baseURL = "http://localhost:8000";
    }

    Vue.axios.defaults.withCredentials = false;
    Vue.axios.defaults.timeout = 60000;
    Vue.axios.defaults.baseURL = this.baseURL;
  }
}
