























import Vue from "vue";
import Component from "vue-class-component";
import { SnackbarEventType } from "@/modules/global-snackbar/snackbarModule";

@Component
export default class GlobalSnackbar extends Vue {
  // 📦 The message that should be displayed
  private snackbarMessage: string = "";

  // 📦 The type of message that should be displayed
  private snackbarType: SnackbarEventType = SnackbarEventType.Informational;

  // 🚩 Flag for whether the snackbar should be shown
  private showSnackbar: boolean = false;

  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type !== "publishSnackbarEvent") return;
      if (mutation.payload.message !== "") {
        this.showSnackbar = false;
        this.showSnackbar = true;
        this.snackbarMessage = mutation.payload.message;
        this.snackbarType = mutation.payload.type as SnackbarEventType;
      }
    });
  }
}
