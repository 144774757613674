import store from "@/store";
import AdminRoleWithPermissions from "@/utility/admin-users/adminRoleWithPermissions";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

/**
 * Module which aims to provide a state that can be accessed throughtout the application
 */
@Module
export default class GlobalStateModule extends VuexModule {
  /**
   * The authorization token that should be persisted
   */
  public authorizationToken?: string;

  /**
   * Flag for whether the current user is authorized
   */
  public isAuthorized?: boolean;

  public serverList?: any;
  public selectedServer?: string;

  public currentUser?: string

  public currentUserRoleWithPermissions?: AdminRoleWithPermissions;

  @Mutation
  public updateServerList(newList?: any) {
    this.serverList = newList;
  }

  @Mutation
  public updateSelectedServer(newServer?: string) {
    this.selectedServer = newServer;
  }

  /**
   * Updates the current value of the authorization token
   * @param newToken - the new value that should be assigned to the authorization token
   */
  @Mutation
  public updateAuthorizationToken(newToken?: string) {
    this.authorizationToken = newToken;
  }

  /**
   * Updates the authorization flag for the current user
   * @param isAuthorized
   */
  @Mutation
  public updateIsAuthorized(isAuthorized?: boolean) {
    this.isAuthorized = isAuthorized;
  }

  @Mutation
  public setCurrentUser(username: string) {
    this.currentUser = username;
  }

  @Mutation
  public updateCurrentUserRoleAndPermissions(adminRolesWithPermissions: AdminRoleWithPermissions) {
    this.currentUserRoleWithPermissions = adminRolesWithPermissions;
  }

  /**
   * 🚮 Clears the Vuex store
   */
  @Action
  public clearState() {
    store.replaceState({
      globalStateModule: {
        serverList: (store.state as any).globalStateModule.serverList,
        selectedServer: (store.state as any).globalStateModule.selectedServer,
        authorizationToken: undefined,
        isAuthorized: false,
        currentUserRoleWithPermissions: undefined,
        currentUser: undefined
      },
      eventBus: {},
      globalSnackbar: {},
      subscriptionTiers: {
        subscriptionTiers: []
      },
      users: {
        users: [],
        lastRefresh: null,
        areUsersFetched: false
      },
      bots: {
        activeBots: [],
        lastRefreshOfActiveBots: null,
        areActiveBotsFetched: false,
        inactiveBots: [],
        lastRefreshOfInactiveBots: null,
        areInactiveBotsFetched: false,
        botsPerPage: 15
      },
      adminUsers: {
        adminUsers: []
      },
      adminRoles: {
        adminRolesWithPermissions: []
      },
      wallet: {
        walletDetails: null,
        walletTransactions: []
      }
    });
  }

  /**
   * 🚮 Clears the cached data on server switch
   */
  @Action
  public async clearDataOnServerSwitch() {
    const currentVuexState = (store as any).state;

    currentVuexState.subscriptionTiers = {
      subscriptionTiers: []
    };
    currentVuexState.users = {
      users: [],
      lastRefresh: null,
      areUsersFetched: false
    };
    currentVuexState.bots = {
      activeBots: [],
      lastRefreshOfActiveBots: null,
      areActiveBotsFetched: false
    };

    store.replaceState(currentVuexState);

    store.commit("setUser", []);
    store.commit("setLastRefreshTime", undefined);
    store.commit("setUsersAreFetched", false);

    store.commit("setSubscriptionTiers", []);

    store.commit("setActiveBots", []);
    store.commit("setActiveBotsAreFetched", false);
    store.commit("setActiveBotsLastRefreshTime", null);

    store.commit("setInactiveBots", []);
    store.commit("setInactiveBotsAreFetched", false);
    store.commit("setInactiveBotsLastRefreshTime", null);

    store.commit("setAdminUsers", []);
    store.commit("setAdminRoles", []);
  }
}
