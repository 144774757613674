

























import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import Vue from "vue";
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";

@Component({
  mixins: [PermissionCheckerMixin]
})
export default class NFTTiers extends Base {
  // 📦 The JSON rendered for the NFT holding tiers
  private nftTiers: string = "";

  // 🚩 Flag for whether the NFT tiers are being saved on the API
  private isSavingTiers: boolean = false;

  protected async mounted() {
    super.mounted();
    this.fetchNFTTiers();
  }

  /**
   * 🚀 Fetches the NFT tiers from the API and formats it
   */
  private fetchNFTTiers() {
    Vue.prototype.$api
      .get("/api/proxy", "/api/v1/admin/nfttiersconfig")
      .then((response: any) => {
        this.nftTiers = JSON.stringify(response.data, null, "    ");
      });
  }

  /**
   * 🚀 Saves the updated NFT tiers on the API
   */
  private saveNFTTiers() {
    this.isSavingTiers = true;
    Vue.prototype.$api
      .post(
        "/api/proxy",
        "/api/v1/admin/nfttiersconfig",
        JSON.parse(this.nftTiers)
      )
      .then((response: any) => {
        this.showSuccessSnackbar(
          "OneButtonBots tiers were successfully updated!"
        );
        this.fetchNFTTiers();
      })
      .finally(() => (this.isSavingTiers = false));
  }
}
