



















import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import AnalyticsChartWrapper from "./components/AnalyticsChartsWrapper.vue";
import ActiveBotsChart from "./components/OBTEarningsChart.vue";
import OBvsTopMarketsChart from "./components/OBvsTopMarketsChart.vue";

@Component({
  components: { AnalyticsChartWrapper, ActiveBotsChart, OBvsTopMarketsChart }
})
export default class Analytics extends Base {
  protected async mounted() {
    super.mounted();
  }
}
