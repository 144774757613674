























































import Vue from "vue";
import { Component, PropSync, Watch } from "vue-property-decorator";

@Component
export default class CreateNewAdminDialog extends Vue {
  // 🚩 Flag for whether the dialog is shown
  @PropSync("showDialog", { type: Boolean })
  public show!: boolean;

  // 📦 The selected admin role for the new admin user
  private selectedAdminRole: string | null = null;

  // 📦 The username for the new admin user
  private newAdminUserUsername: string = "";

  // 📦 The password for the new admin user
  private newAdminUserPassword: string = "";

  // 🚩 Flag responsible for revealing the user's password
  private passwordVisible = false;

  // 📦 All of the available admin user roles
  private get adminUserRoles(): string[] {
    return this.$store.getters.adminUserRoles;
  }

  // 🚩 Flag for whether the entered information about the new admin user is correct
  private get isContentValid(): boolean {
    return (
      this.selectedAdminRole !== null &&
      this.newAdminUserUsername !== "" &&
      this.newAdminUserPassword !== ""
    );
  }

  /**
   * 👂 Listener for changes in the available admin user roles
   */
  @Watch("adminUserRoles", { immediate: true })
  private onAdminUserRolesChange(val: string[], oldVal: string[]) {
    this.selectedAdminRole = val[0];
  }

  /**
   * 🔨 Triggers the creation of a new admin user
   */
  private async createNewAdminUser() {
    await this.$store.dispatch("createAdminUser", {
      username: this.newAdminUserUsername,
      password: this.newAdminUserPassword,
      role: this.selectedAdminRole
    });
    this.show = false
  }
}
