


























import { Component, Watch, Prop } from "vue-property-decorator";
import BaseChart from "./BaseChart.vue";

@Component
export default class BalanceInUse extends BaseChart {
  // 📦 The selected filter for days
  @Prop({ default: 0 })
  public selectedFilterInDays!: number;

  // 🚩 Flag for whether a loading indicator should be shown
  @Prop({ required: true, default: false })
  public isLoading!: boolean;

  // 📦 Dataset to be displayed
  @Prop({ required: true })
  public dataset!: number[];

  // 📦 Dates for point on the chart
  @Prop({ required: true, default: [] })
  public dateLabels!: Date[];

  // 🎨 Possible views that can be shown in this component
  private COMPONENTS_TO_SHOW = {
    LOADING: 0,
    CHART: 1
  };

  // 📦 The currently displayed component
  private currentlyShownComponent: number = this.COMPONENTS_TO_SHOW.CHART;

  /**
   * 👂 Listener used in order to determine the selected chart filter mode
   */
  @Watch("dataset", { immediate: true, deep: true })
  private onDatasetChanged(val: number[], oldVal?: number[]) {
    this.createChart(this.dateLabels, val, this.selectedFilterInDays);
  }

  /**
   * 👂 Listener used in order to determine the selected chart filter mode
   */
  @Watch("selectedFilterInDays", { immediate: true, deep: true })
  private onSelectedFilterInDaysChanged(val: number, oldVal?: number) {
    this.createChart(this.dateLabels, this.dataset, this.selectedFilterInDays);
  }

  /**
   * 👂 Listener used in order to determine the selected chart filter mode
   */
  @Watch("isLoading")
  private onIsLoadingChanged(val: boolean, oldVal?: boolean) {
    if (val) {
      this.currentlyShownComponent = this.COMPONENTS_TO_SHOW.LOADING;
    } else {
      this.currentlyShownComponent = this.COMPONENTS_TO_SHOW.CHART;
    }
  }

  updated() {
    this.createChart(this.dateLabels, this.dataset, this.selectedFilterInDays);
  }

  /**
   * 🏗 The element ID of the current chart
   */
  protected chartElementId(): string {
    return "container-balance-in-use-chart";
  }

  /**
   * 🔨 Returns the datasets with their display porperties that should be displayed on the chart
   */
  protected getDataSets() {
    let dataSets = [];

    // Create gradient fill
    var gradient = (this.$refs.balanceInUseChart as any)
      .getContext("2d")
      .createLinearGradient(
        0,
        0,
        0,
        (this.$refs.balanceInUseChart as any).clientHeight
      );
    gradient.addColorStop(0, "rgba(52, 118, 235, 0.86)");
    gradient.addColorStop(1, "rgba(101, 217, 147, 0.15)");

    if (this.dataset.length > 0) {
      dataSets.push({
        label: "Balance In Use",
        data: this.dataset,
        borderColor: "#3476EB",
        backgroundColor: gradient,
        pointRadius: 1,
        borderWidth: 2
      });
    }

    return dataSets;
  }
}
