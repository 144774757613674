var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"px-0 pt-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-5 mx-0",attrs:{"align":"center"}},[_c('v-chip-group',{attrs:{"mandatory":"","active-class":"primary--text"},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}},_vm._l((_vm.chartFilters),function(filter){return _c('v-chip',{key:filter,attrs:{"color":"transparent","text-color":"grey"}},[_vm._v(" "+_vm._s(filter)+" ")])}),1),_c('v-select',{staticClass:"ml-2 ml-md-5",staticStyle:{"max-width":"250px"},attrs:{"items":_vm.availableSubscriptionTiers,"label":"Plan Type","hide-details":"","outlined":"","flat":"","dense":""},model:{value:(_vm.selectedSubscriptionType),callback:function ($$v) {_vm.selectedSubscriptionType=$$v},expression:"selectedSubscriptionType"}}),_c('v-select',{staticClass:"ml-2",staticStyle:{"max-width":"250px"},attrs:{"items":_vm.availableAIStrategies,"label":"AI Strategy","hide-details":"","outlined":"","flat":"","dense":""},model:{value:(_vm.selectedAIStrategy),callback:function ($$v) {_vm.selectedAIStrategy=$$v},expression:"selectedAIStrategy"}})],1),_c('v-row',{staticClass:"mt-10 mx-0"},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('span',[_vm._v("Balance In Use")]),(_vm.balanceInUseDataset && _vm.balanceInUseDataset.length > 0)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(_vm.balanceInUseDataset[_vm.balanceInUseDataset.length - 1].toFixed(2))+" ")]):_vm._e(),(
            _vm.balanceInUseDataset &&
              _vm.balanceInUseDataset.length > 0 &&
              _vm.balanceInUseDataset[0] != 0
          )?_c('span',{class:_vm.getIndicatorColor(
              _vm.totalChangeInBasicDatasetPeriod(_vm.balanceInUseDataset)
            )},[_vm._v(" ( "+_vm._s(_vm.getIndicatorDisplayValue( _vm.totalChangeInBasicDatasetPeriod(_vm.balanceInUseDataset) ))+" ) ")]):_vm._e()]),_c('BalanceInUse',{attrs:{"selectedFilterInDays":_vm.selectedFilterInDays,"isLoading":_vm.isLoadingNewData,"dataset":_vm.balanceInUseDataset,"dateLabels":_vm.dateLabels}})],1),_c('v-col',{staticClass:"pl-0 pr-lg-10",attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('span',[_vm._v("Total Users")]),(_vm.totalUsersDataset)?_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v(" "+_vm._s(_vm.totalUsersDataset[_vm.totalUsersDataset.length - 1])+" ")]):_vm._e(),(
            _vm.totalUsersDataset &&
              _vm.totalUsersDataset.length > 0 &&
              _vm.totalUsersDataset[0] != 0
          )?_c('span',{class:_vm.getIndicatorColor(
              _vm.totalChangeInBasicDatasetPeriod(_vm.totalUsersDataset)
            )},[_vm._v(" ( "+_vm._s(_vm.getIndicatorDisplayValue( _vm.totalChangeInBasicDatasetPeriod(_vm.totalUsersDataset) ))+" ) ")]):_vm._e()]),_c('TotalUsersChart',{attrs:{"selectedFilterInDays":_vm.selectedFilterInDays,"isLoading":_vm.isLoadingNewData,"dataset":_vm.totalUsersDataset,"dateLabels":_vm.dateLabels}})],1)],1),_c('v-row',{staticClass:"mt-lg-5 mx-0"},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('span',[_vm._v("Total Active Bots")]),(_vm.activeBotsDataset && _vm.activeBotsDataset.length > 0)?_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v(" "+_vm._s(_vm.activeBotsDataset[_vm.activeBotsDataset.length - 1])+" ")]):_vm._e(),(
            _vm.activeBotsDataset &&
              _vm.activeBotsDataset.length > 0 &&
              _vm.activeBotsDataset[0] != 0
          )?_c('span',{class:_vm.getIndicatorColor(
              _vm.totalChangeInBasicDatasetPeriod(_vm.activeBotsDataset)
            )},[_vm._v(" ( "+_vm._s(_vm.getIndicatorDisplayValue( _vm.totalChangeInBasicDatasetPeriod(_vm.activeBotsDataset) ))+" ) ")]):_vm._e()]),_c('ActiveBotsChart',{attrs:{"selectedFilterInDays":_vm.selectedFilterInDays,"isLoading":_vm.isLoadingNewData,"dataset":_vm.activeBotsDataset,"dateLabels":_vm.dateLabels}})],1),_c('v-col',{staticClass:"pl-0 pr-lg-10",attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('span',[_vm._v("Average Bot Balance")]),(
            _vm.averageBotBalanceDataSet && _vm.averageBotBalanceDataSet.length > 0
          )?_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v(" $"+_vm._s(_vm.averageBotBalanceDataSet[ _vm.averageBotBalanceDataSet.length - 1 ].toFixed(2))+" ")]):_vm._e(),(
            _vm.averageBotBalanceDataSet &&
              _vm.averageBotBalanceDataSet.length > 0 &&
              _vm.averageBotBalanceDataSet[0] != 0
          )?_c('span',{class:_vm.getIndicatorColor(
              _vm.totalChangeInBasicDatasetPeriod(_vm.averageBotBalanceDataSet)
            )},[_vm._v(" ( "+_vm._s(_vm.getIndicatorDisplayValue( _vm.totalChangeInBasicDatasetPeriod(_vm.averageBotBalanceDataSet) ))+" ) ")]):_vm._e()]),_c('AverageBotBalanceChart',{attrs:{"selectedFilterInDays":_vm.selectedFilterInDays,"isLoading":_vm.isLoadingNewData,"dataset":_vm.averageBotBalanceDataSet,"dateLabels":_vm.dateLabels}})],1)],1),_c('v-row',{staticClass:"mt-lg-5 mx-0"},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('span',[_vm._v("Trade Volume")]),(_vm.tradeVolumeDataset)?_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v(" $"+_vm._s(Array.from(_vm.tradeVolumeDataset.values())[ Array.from(_vm.tradeVolumeDataset.values()).length - 1 ])+" ")]):_vm._e(),(
            _vm.tradeVolumeDataset &&
              Array.from(_vm.tradeVolumeDataset.values()).length > 0 &&
              Array.from(_vm.tradeVolumeDataset.values())[0] != 0
          )?_c('span',{class:_vm.getIndicatorColor(
              _vm.totalChangeInBasicDatasetPeriod(
                Array.from(_vm.tradeVolumeDataset.values())
              )
            )},[_vm._v(" ( "+_vm._s(_vm.getIndicatorDisplayValue( _vm.totalChangeInBasicDatasetPeriod( Array.from(_vm.tradeVolumeDataset.values()) ) ))+" ) ")]):_vm._e()]),_c('TradeVolumeChart',{attrs:{"selectedFilterInDays":_vm.selectedFilterInDays,"isLoading":_vm.isLoadingNewData,"dataset":Array.from(_vm.tradeVolumeDataset.values()),"dateLabels":Array.from(_vm.tradeVolumeDataset.keys())}})],1),_c('v-col',{staticClass:"pl-0 pr-lg-10",attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('span',[_vm._v("Total Connected Exchanges")]),(_vm.totalConnectedExchangesDataSet)?_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v(" "+_vm._s(_vm.totalConnectedExchangesDataSet[ _vm.totalConnectedExchangesDataSet.length - 1 ])+" ")]):_vm._e(),(
            _vm.totalConnectedExchangesDataSet &&
              _vm.totalConnectedExchangesDataSet.length > 0 &&
              _vm.totalConnectedExchangesDataSet[0] != 0
          )?_c('span',{class:_vm.getIndicatorColor(
              _vm.totalChangeInBasicDatasetPeriod(_vm.totalConnectedExchangesDataSet)
            )},[_vm._v(" ( "+_vm._s(_vm.getIndicatorDisplayValue( _vm.totalChangeInBasicDatasetPeriod(_vm.totalConnectedExchangesDataSet) ))+" ) ")]):_vm._e()]),_c('TotalExchangesChart',{attrs:{"selectedFilterInDays":_vm.selectedFilterInDays,"isLoading":_vm.isLoadingNewData,"dataset":_vm.totalConnectedExchangesDataSet,"dateLabels":_vm.dateLabels}})],1)],1),_c('v-row',{staticClass:"mt-lg-5 mx-0"},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('span',[_vm._v("Trades Made")]),(_vm.tradesMadeDataset && _vm.tradesMadeDataset.length > 0)?_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v(" Buy: "+_vm._s(_vm.tradesMadeDataset[_vm.tradesMadeDataset.length - 1].buy)+" | Sell: "+_vm._s(_vm.tradesMadeDataset[_vm.tradesMadeDataset.length - 1].sell)+" ")]):_vm._e()]),_c('TradesMadeChart',{attrs:{"selectedFilterInDays":_vm.selectedFilterInDays,"isLoading":_vm.isLoadingNewData,"dataset":_vm.tradesMadeDataset,"dateLabels":_vm.dateLabels}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}})],1),_c('v-row',{staticClass:"mt-lg-5 mx-0"},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('span',[_vm._v("Average Balance In Use / User")]),(_vm.averageBalanceInUsePerAccount)?_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v(" $"+_vm._s(Array.from(_vm.averageBalanceInUsePerAccount.values())[ Array.from(_vm.averageBalanceInUsePerAccount.values()).length - 1 ])+" ")]):_vm._e(),(
            _vm.averageBalanceInUsePerAccount &&
              Array.from(_vm.averageBalanceInUsePerAccount.values()).length > 0 &&
              Array.from(_vm.averageBalanceInUsePerAccount.values())[0] != 0
          )?_c('span',{class:_vm.getIndicatorColor(
              _vm.totalChangeInBasicDatasetPeriod(
                Array.from(_vm.averageBalanceInUsePerAccount.values())
              )
            )},[_vm._v(" ( "+_vm._s(_vm.getIndicatorDisplayValue( _vm.totalChangeInBasicDatasetPeriod( Array.from(_vm.averageBalanceInUsePerAccount.values()) ) ))+" ) ")]):_vm._e()]),_c('AverageBalanceInUsePerUserChart',{attrs:{"selectedFilterInDays":_vm.selectedFilterInDays,"isLoading":_vm.isLoadingNewData,"dataset":Array.from(_vm.averageBalanceInUsePerAccount.values()),"dateLabels":Array.from(_vm.averageBalanceInUsePerAccount.keys())}})],1),_c('v-col',{staticClass:"pl-0 pr-lg-10",attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('span',[_vm._v("Average Active Bots / User")]),(_vm.averageActiveBotsPerAccount)?_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v(" "+_vm._s(Array.from(_vm.averageActiveBotsPerAccount.values())[ Array.from(_vm.averageActiveBotsPerAccount.values()).length - 1 ])+" ")]):_vm._e(),(
            _vm.averageActiveBotsPerAccount &&
              Array.from(_vm.averageActiveBotsPerAccount.values()).length > 0 &&
              Array.from(_vm.averageActiveBotsPerAccount.values())[0] != 0
          )?_c('span',{class:_vm.getIndicatorColor(
              _vm.totalChangeInBasicDatasetPeriod(
                Array.from(_vm.averageActiveBotsPerAccount.values())
              )
            )},[_vm._v(" ( "+_vm._s(_vm.getIndicatorDisplayValue( _vm.totalChangeInBasicDatasetPeriod( Array.from(_vm.averageActiveBotsPerAccount.values()) ) ))+" ) ")]):_vm._e()]),_c('AverageActiveBotsPerUserChart',{attrs:{"selectedFilterInDays":_vm.selectedFilterInDays,"isLoading":_vm.isLoadingNewData,"dataset":Array.from(_vm.averageActiveBotsPerAccount.values()),"dateLabels":Array.from(_vm.averageActiveBotsPerAccount.keys())}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }