/**
 * 📄 Class that represents a single coin on the dashboard
 */
export default class CoinHeld {
  constructor(
    public name: string,
    public value: number,
    public percentageOfAll: number,
    public amount: number
  ) {}
}