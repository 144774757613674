































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import RequestModel from "@/utility/request";
import DateFormatterMixin from "@/mixins/dateFormatterMixin";

@Component({
  mixins: [DateFormatterMixin]
})
export default class Requests extends Base {
  data() {
    return {
      sortBy: "period",
      sortDesc: true
    };
  }
  private requests: RequestModel[] = [];
  private headers = [
    { text: "E-mail", value: "email" },
    { text: "Name", value: "name" },
    { text: "Auto Approval Time", value: "period" },
    { text: "Actions", value: "actions" }
  ];

  // 🚩 Flag for whether the requests are loading
  private isLoading: boolean = false;

  protected async mounted() {
    await super.mounted();
    this.fetchRequests();
  }

  private async fetchRequests() {
    this.isLoading = true;
    Vue.prototype.$api
      .get("/api/proxy", "/api/v1/admin/requests")
      .then((response: any) => (this.requests = response.data.result.map((request: any) => {
        request.period = (new Date().getTime() / 1000) + request.period * -1
        return request
      })))
      .finally(() => (this.isLoading = false));
  }

  private async approveRequest(email: string) {
    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/requests", { email })
      .then((response: any) => {
        super.showSuccessSnackbar("The request was successfully approved");
        this.fetchRequests();
      });
  }

  private async denyRequest(email: string) {
    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/denyrequest", { email })
      .then((response: any) => {
        super.showSuccessSnackbar("The request was successfully denied");
        this.fetchRequests();
      });
  }
}
