var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('v-data-table',{attrs:{"headers":_vm.adminRolesTableHeaders,"items":_vm.adminRolesWithPermissions,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.permissions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2",domProps:{"innerHTML":_vm._s(_vm.formatAdminRolePermissions(item))}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"d-flex":"","justify-end":""}},[(_vm.isFunctionalityAllowedForUser('superadmin', 'modify'))?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.selectedAdminRoleWithPermissions = item;
            _vm.showEditAdminRoleDialog = true;}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.isFunctionalityAllowedForUser('superadmin', 'delete'))?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"red"},on:{"click":function($event){_vm.selectedAdminRoleWithPermissions = item;
            _vm.showDeleteAdminRoleDialog = true;}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}])}),(
      _vm.selectedAdminRoleWithPermissions &&
        _vm.isFunctionalityAllowedForUser('superadmin', 'delete')
    )?_c('ConfirmationDialog',{attrs:{"title":'Delete ' + _vm.selectedAdminRoleWithPermissions.role + ' role?',"message":'Are you sure you want to delete ' +
        _vm.selectedAdminRoleWithPermissions.role +
        '? All the admin users with this role will be automatically transferred to Blocked role.',"showDialog":_vm.showDeleteAdminRoleDialog},on:{"confirmationGiven":function($event){return _vm.$store.dispatch('deleteAdminRole', _vm.selectedAdminRoleWithPermissions.role)},"update:showDialog":function($event){_vm.showDeleteAdminRoleDialog=$event},"update:show-dialog":function($event){_vm.showDeleteAdminRoleDialog=$event}}}):_vm._e(),(
      _vm.selectedAdminRoleWithPermissions &&
        _vm.isFunctionalityAllowedForUser('superadmin', 'modify')
    )?_c('EditAdminRoleDialog',{attrs:{"adminRoleWithPermissions":_vm.selectedAdminRoleWithPermissions,"showDialog":_vm.showEditAdminRoleDialog},on:{"update:showDialog":function($event){_vm.showEditAdminRoleDialog=$event},"update:show-dialog":function($event){_vm.showEditAdminRoleDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }