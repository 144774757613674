import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment";
import { getSecondsFromCandleStickSize } from "@/utils/botStatusUtils";

@Component
export default class DateFormatter extends Vue {
  /**
   * 📦 Possible date formats
   */
  private DateFormats = {
    dateAndTime: "DD/MM/YYYY HH:mm:ss",
    date: "DD/MM/YYYY"
  };

  /**
   * 🕓 Formats the raw date received from the api to DD/MM/YYYY HH:mm:ss date string
   * @param dateString
   */
  public formatDateFromAPI(
    dateString: number,
    dateAndTime: boolean = true
  ): string {
    return moment
      .unix(Number(dateString))
      .format(
        dateAndTime ? this.DateFormats.dateAndTime : this.DateFormats.date
      );
  }

  /**
   * 🕓 Calculates and returns the time since the provided date ("1d 3h 15m 43s")
   * @param date
   * @returns the formatted time
   */
  public timeSince(date: Date, includeSeconds: boolean = true): string {
    let time = this.dateDifference(
      new Date(),
      date,
      includeSeconds
        ? ["day", "hour", "minute", "second"]
        : ["day", "hour", "minute"]
    );

    let formattedTime = "Just Now";
    let timeEntries = Object.entries(time);
    for (let [timeType, timeValue] of timeEntries) {
      if (timeValue != 0) {
        formattedTime = "";
        const startIndex = Array.from(timeEntries).findIndex(entry => {
          return entry[0] == timeType;
        });
        for (let i = startIndex; i < timeEntries.length; i++) {
          formattedTime +=
            timeEntries[i][1] +
            timeEntries[i][0][0] +
            (i < timeEntries.length - 1 ? " " : " ago");
        }
        break;
      }
    }
    return formattedTime;
  }

  public calculateTimeBetweenDates(dateStart: Date, dateEnd: Date) {
    let time = this.dateDifference(dateStart, dateEnd, [
      "day",
      "hour",
      "minute"
    ]);

    let formattedTime = "<1m";
    let timeEntries = Object.entries(time);
    for (let [timeType, timeValue] of timeEntries) {
      if (timeValue != 0) {
        formattedTime = "";
        const startIndex = Array.from(timeEntries).findIndex(entry => {
          return entry[0] == timeType;
        });
        for (let i = startIndex; i < timeEntries.length; i++) {
          formattedTime +=
            timeEntries[i][1] +
            timeEntries[i][0][0] +
            (i < timeEntries.length - 1 ? " " : "");
        }
        break;
      }
    }

    return formattedTime;
  }

  /**
   * ☃ dateDiff "Snowman Carl" (http://stackoverflow.com/questions/13903897)
   * Returns a detail object about the difference between two dates
   *
   * When providing custom units, provide them in descending order (eg week,day,hour; not hour,day,week)
   *
   * @param {Date} dateStart - date to compare to
   * @param {Date|string} [dateEnd=new Date()] - second date, can be used as unit param instead
   * @param {...string} [units=Object.keys(dateDiffDef)] - limits the returned object to provided keys
   */
  public dateDifference(
    dateStart: Date,
    dateEnd: Date | string = new Date(),
    units: string[]
  ): {
    [key: string]: number;
  } {
    if (typeof dateEnd === "string") dateEnd = new Date();

    let delta: number = Math.abs(dateStart.getTime() - dateEnd.getTime());

    return (units.length ? units : Object.keys(this.dateDiffDef)).reduce(
      (res: any, key: any) => {
        if (!this.dateDiffDef.hasOwnProperty(key))
          throw new Error("Unknown unit in dateDiff: " + key);
        res[key] = Math.floor(delta / this.dateDiffDef[key]);
        delta -= res[key] * this.dateDiffDef[key];
        return res;
      },
      {}
    );
  }

  // default time units for dateDiff
  private dateDiffDef: any = {
    day: 86400000,
    hour: 3600000,
    minute: 60000,
    second: 1000
  };
}
