




























import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ServerDialog from "./ServerDialog.vue";

interface Server {
  id?: string;
  host?: string;
  url?: string;
  name: string;
}

@Component({
  components: { ServerDialog }
})
export default class ServerSelector extends Vue {
  private showServerDialog: boolean = false;
  private serverList: Server[] | null = null;
  private selected: Server | null = null;
  private hint: string = "";

  @Watch("selected")
  private async onSelectedChanged(val: Server, oldVal: Server) {
    if (
      val.id != undefined &&
      this.$store.state.globalStateModule.selectedServer !== val.id
    ) {
      this.$store.commit("updateSelectedServer", val.id);
      await this.$store.dispatch("clearDataOnServerSwitch");
      this.$router.go(0);
    }
  }

  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "updateServerList") {
        this.serverList = this.convertReceivedServerObjects(mutation.payload);
      }
      if (mutation.type === "updateSelectedServer") {
        this.selected = this.getSelectedServer();
        this.showSelectedServerHint();
      }
    });

    // On load display selected server
    this.serverList = this.convertReceivedServerObjects(
      this.$store.state.globalStateModule.serverList
    );
    this.selected = this.getSelectedServer();
    this.showSelectedServerHint();
  }

  /**
   * Returns the selected server
   */
  private getSelectedServer(): Server | null {
    var selectedServer = this.serverList?.filter(
      (element: Server, index: number, array: Server[]) => {
        return element.id == this.$store.state.globalStateModule.selectedServer;
      }
    )[0];
    return !!selectedServer ? selectedServer : null;
  }

  /**
   * Properly maps the raw response to server objects with id
   */
  private convertReceivedServerObjects(rawServers: any): any[] {
    var convertedServerObjects = [];
    for (const k in rawServers) {
      convertedServerObjects.push({
        id: k,
        ...rawServers[k]
      });
    }
    return convertedServerObjects;
  }

  /**
   * Sets a hint about the selected server and its properties
   */
  private showSelectedServerHint() {
    this.hint = `${(this.selected?.id || "").substring(0, 5)} (${this.selected
      ?.host ||
      this.selected?.url ||
      ""})`;
  }
}
