






































import Base from "../Base.vue";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component
export default class Markets extends Base {
  // 🚩 Flag for whether the dialog is shown
  @PropSync("showDialog", { type: Boolean })
  public show!: boolean;

  // 📦 Exchange to which a new market should be added
  @Prop()
  public exchange!: string;
}
