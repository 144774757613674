







































































































































































































































import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import Vue from "vue";
import SubscriptionTier from "@/utility/subscriptionTier.ts";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import DateFormatter from "@/mixins/dateFormatterMixin";
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";
import EditRawSubscriptionPlansDialog from "./EditRawSubscriptionPlansDialog.vue";

@Component({
  components: { ConfirmationDialog, EditRawSubscriptionPlansDialog },
  mixins: [DateFormatter, PermissionCheckerMixin]
})
export default class SubscriptionTiers extends Base {
  // 📦 The available subscription tiers
  private get subscriptionTiers(): SubscriptionTier[] {
    return this.$store.state.subscriptionTiers.subscriptionTiers;
  }

  // 🚩 Flag for whether the price id fields should be locked or not
  private lockPriceIdsField: boolean = true;

  // 🚩 Flag for whether the dialog for saving the new configuration should be shown
  private showSaveConfigurationDialog: boolean = false;

    // 🚩 Flag for whether the dialog for edditing the subscriptions should be shown
  private showEditPlansDialog: boolean = false;

  // 🚩 Flag for whether the new plans are being saved on the API
  private isSavingSubscriptionPlans: boolean = false;

  protected async mounted() {
    await super.mounted();
    this.$store.dispatch("fetchSubscriptionPlans");
    // Uncomment whenever there is mechanism to handle common benefits on the frontend
    // this.fetchCommonBenefits();
  }

  /**
   * 🚀 Sends new configuration
   */
  private async saveUpdatedConfiguration() {
    try {
      this.isSavingSubscriptionPlans = true;
      await this.$store.dispatch(
        "saveSubscriptionPlans",
        this.subscriptionTiers
      );
    } finally {
      this.isSavingSubscriptionPlans = false;
    }
  }

  /**
   * 🚀 Fetches the common subscription plan benefits from the API
   */
  private async fetchCommonBenefits() {
    Vue.prototype.$api
      .get("/api/proxy", "/api/v1/admin/commonbenefits")
      .then((response: any) => {
        console.log(response.data);
      });
  }
}
