
























































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ServerSelector from "./ServerSelector.vue";
import { isPageAllowedForUser } from "@/utils/permissionChecker";

@Component({
  components: { ServerSelector }
})
export default class Navbar extends Vue {
  private isOpen = false;
  private drawer = false;

  private showLogoutDialog: boolean = false;

  private authorizationToken: string = "";

  private options = [
    {
      permission: "users",
      item: {
        icon: "mdi-account-group",
        title: "Users",
        desiredRoute: "/users"
      }
    },
    {
      permission: "bots",
      item: { icon: "mdi-robot", title: "Bots", desiredRoute: "/bots" }
    },
    {
      permission: "stats",
      item: {
        icon: "mdi-cloud-print-outline",
        title: "Server & API Stats",
        desiredRoute: "/stats"
      }
    },
    {
      permission: "models",
      item: {
        icon: "mdi-brain",
        title: "AI Models",
        desiredRoute: "/models"
      }
    },
    {
      permission: "models",
      item: {
        icon: "mdi-bitcoin",
        title: "Markets",
        desiredRoute: "/markets"
      }
    },
    {
      permission: "analytics",
      item: {
        icon: "mdi-google-analytics",
        title: "Analytics",
        desiredRoute: "/analytics"
      }
    },
    {
      permission: "subscriptions",
      item: {
        icon: "mdi-credit-card-multiple-outline",
        title: "Subscription",
        desiredRoute: "/subscriptions"
      }
    },
    {
      permission: "logs",
      specificPageEnablePermission: "tree",
      item: {
        icon: "mdi-text-box-outline",
        title: "Logs",
        desiredRoute: "/logs"
      }
    },
    {
      permission: "superadmin",
      item: {
        icon: "mdi-account-hard-hat",
        title: "Admin Users & Roles",
        desiredRoute: "/admin-users"
      }
    }
  ];

  // 📦 Options which are allowed for the current admin user
  private get filteredOptions(): any[] {
    return this.options
      .filter((option: any) =>
        isPageAllowedForUser(
          option.permission,
          option.specificPageEnablePermission
        )
      )
      .map((option: any) => option.item);
  }

  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "updateIsAuthorized") {
        this.drawer = mutation.payload !== false && mutation.payload !== null;
      }
    });
  }

  private logout() {
    this.$store.commit("updateIsAuthorized", false);
    this.$store.commit("updateAuthorizationToken", undefined);
    Vue.axios.defaults.headers.common["Authorization"] = "";
    this.$store.dispatch("clearState");
    this.$router.push("/login");
  }
}
