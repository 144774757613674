
/**
 * 📈
 * Model representing the stats of a certain trading bot
 */
export default class TradingBotStats {
  public roi: number;
  public bah_roi: number;
  public avg_fee: number;
  public avg_roi_trade: number;
  public avg_roi_month: number;

  constructor(
    public curBalance: number,
    public tokBalance: number,
    public portfolioValue: number,
    public max_balance: number,
    public min_balance: number,
    public uid: string,
    public exchange: string,
    public strategy: string,
    public strategy_name: string,
    public in_fees: number,
    public market: string,
    public candles: string,
    public ml_boost: boolean,
    public startingBalance: number[],
    public start_time: number,
    public stop_time: number,
    roi: number,
    public bot_profit: number,
    bah_roi: number,
    public bah_profit: number,
    public enabled: boolean,
    avg_fee: number,
    avg_roi_trade: number,
    avg_roi_month: number,
    public image: string,
    public description: string,
    public sharpe: number,
    public sortino: number,
    public maximum_drawdown: number,
    public last_trade_attempt?: number,
  ) {
    this.roi = roi * 100;
    this.bah_roi = bah_roi * 100;
    this.avg_fee = avg_fee * 100;
    this.avg_roi_trade = avg_roi_trade * 100;
    this.avg_roi_month = avg_roi_month * 100;
  }

    public get outperformance() {
      return this.roi - this.bah_roi
    }
    public get startingBalanceQuoteCurrency() {
      return this.startingBalance[1];
    }
 

  static newInstance(response: any): TradingBotStats {
    return new TradingBotStats(
      response.curBalance,
      response.tokBalance,
      response.portfolioValue,
      response.max_balance,
      response.min_balance,
      response.uid,
      response.exchange,
      response.strategy,
      response.strategy_name,
      response.in_fees,
      response.market,
      response.candles,
      response.ml_boost,
      response.startingBalance,
      response.start_time,
      response.stop_time,
      response.roi,
      response.bot_profit,
      response.bah_roi,
      response.bah_profit,
      response.enabled,
      response.avg_fee,
      response.avg_roi_trade,
      response.avg_roi_month,
      response.image,
      response.description,
      response.sharpe,
      response.sortino,
      response.maximum_drawdown,
      response.last_trade_attempt
    );
  }
}
