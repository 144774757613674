













































import Base from "../Base.vue";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import LogMessage from "@/utility/logs/logMessage";
import DateFormatter from "@/mixins/dateFormatterMixin";
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";
import {isFunctionalityAllowedForUser} from "@/utils/permissionChecker";

@Component({
  mixins: [DateFormatter, PermissionCheckerMixin]
})
export default class Logs extends Base {
  // 📦 Holds all the tree leafs / items
  private treeItems: any[] = [];

  // 📦 Headers for the individual logs
  private logsTableHeaders = [
    { text: "Message", value: "message" },
    { text: "Level", value: "level" },
    { text: "Process", value: "process" },
    { text: "Date", value: "date" }
  ];

  // 📦 All the logs for the current bot
  private logMessages: LogMessage[] = [];

  // 📦 The selected leafs of the treeview for which logs should be fetched
  private selectedTreeLeafs: string[] = [];

  // 🚩 Flag for whether the logs are being fetched from the API
  private isLoading: boolean = false;

  /**
   * 👂 Listener for changes in the selected leafs of the treeview
   */
  @Watch("selectedTreeLeafs")
  onSelectedTreeLeafsChanged(val: string[], oldVal: string[]) {
    if (val.length > 0) {
      this.fetchSpecificLogs(val[0]);
    }
  }

  protected async mounted() {
    super.mounted();
    this.fetchLogTree();
  }

  /**
   * 🚀 Fetches all the available logs for the current environment
   */
  private fetchLogTree() {
    Vue.prototype.$api
      .get("/api/proxy", "/api/v1/admin/logs")
      .then((response: any) => {
        this.treeItems = [
          this.walkTree(response.data.tree, ["Log tree"])
        ];
      });
  }

  /**
   * 🔨 Itterates through the log tree and formats it in order to be fitting for the tree view elemenet
   */
  private walkTree(tree: any, prefix: any = []) {
    if (tree) {
      const keys = Object.keys(tree);
      for (const k of keys) {
        const path = [...prefix, k];
        tree[k] = this.walkTree(tree[k], path);
      }
    }
    // Finished all leafs below us
    const name = prefix[prefix.length - 1];

    tree = {
      id: prefix.slice(1).join("."),
      name,
      children: Object.values(tree)
    };
    return tree;
  }

  /**
   * 🚀 Fetch logs from the API for a specific id
   */
  private async fetchSpecificLogs(activeId: string) {
    if(!isFunctionalityAllowedForUser("logs", "view")) {
      return;
    }

    this.logMessages = [];
    this.isLoading = true;

    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/logs", {
        leaf: activeId,
        limit: 10000
      })
      .then((response: any) => {
        for (const [key, value] of Object.entries(response.data)) {
          (value as any[]).forEach((logMessage: any) => {
            this.logMessages.push(
              new LogMessage(
                key,
                logMessage.level,
                logMessage.msg,
                logMessage.process
              )
            );
          });
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * 📦 Default sorting by date descending
   */
  private pagination = {
    sortBy: ["date"],
    sortDesc: [true],
    mustSort: true,
    itemsPerPage: 15
  };

  /**
   * 🔨 Custom sorting functionality used in the logs table
   */
  private sortByDate(items: any[], index: string[], isDesc: boolean[]) {
    const dateCompare = (a: any, b: any) =>
      new Date(a).getTime() - new Date(b).getTime();

    items.sort((a, b) => {
      if (index[0] === "date") {
        return dateCompare(a.date, b.date);
      } else {
        return a[index[0]] < b[index[0]] ? -1 : 1;
      }
    });
    if (!isDesc[0]) {
      return items.reverse();
    }
    return items;
  }
}
