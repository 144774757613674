
import Vue from "vue";
import { AxiosError } from "axios";
import { Emit } from "@/api/apiHelper";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
  showInformationSnackbar,
} from "@/utils/snackbarUtils";

export default class Base extends Vue {
  protected async mounted() {
    Vue.axios.defaults.headers.common["Authorization"] =
      this.$store.state.globalStateModule.authorizationToken ||
      Vue.axios.defaults.headers.common["Authorization"];
    this.isAuthorized();

    let r = await Vue.axios.get("/api/servers");
    this.$store.commit("updateServerList", r.data);
    if (!this.$store.state.globalStateModule.selectedServer)
      this.$store.commit("updateSelectedServer", Object.keys(r.data)[0]);
  }

  /**
   * Checks whether the current user is authorized to be on this screen
   */
  private async isAuthorized() {
    Vue.prototype.$api
      .get("/api/login", null, null, Emit.None)
      .then((response: any) => {
        if (response.status === 200) {
          this.$store.commit("updateIsAuthorized", true);
        } else {
          throw new Error("Failed authentication");
        }
      })
      .catch((error: AxiosError) => {
        this.clearExistingAuthorizationToken();
      });
  }

  /**
   * Clears the the existing authorization token and navigates the user to the login page
   */
  private clearExistingAuthorizationToken() {
    Vue.axios.defaults.headers.common["Authorization"] = "";
    this.$store.commit("updateAuthorizationToken", undefined);
    this.redirectUserToLogin();
  }

  /**
   * Redirects the user to the login page
   */
  private redirectUserToLogin() {
    this.$store.commit("updateIsAuthorized", false);
    this.$router.push("/");
  }

  /**
   * 📡 Sends an error event that will be displayed as snackbar
   * @param message - the error message that needs be shown
   */
  protected showErrorSnackbar(message: string) {
    showErrorSnackbar(message);
  }

  /**
   * 📡 Sends an information event that will be displayed as snackbar
   * @param message - the error message that needs be shown
   */
  protected showInformationSnackbar(message: string) {
    showInformationSnackbar(message);
  }

  /**
   * 📡 Sends a success event that will be displayed as snackbar
   * @param message - the success message that needs to be shown
   */
  protected showSuccessSnackbar(message: string) {
    showSuccessSnackbar(message);
  }
}
