

























import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import Vue from "vue";
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";

@Component({
  mixins: [PermissionCheckerMixin]
})
export default class OBTHoldingTiers extends Base {
  // 📦 The JSON rendered for the OBT holding tiers
  private obtHoldingTiers: string = "";

  // 🚩 Flag for whether the OBT holding tiers are being saved on the API
  private isSavingTiers: boolean = false;

  protected async mounted() {
    super.mounted();
    this.fetchOBTHoldingTiers();
  }

  /**
   * 🚀 Fetches the OBT holding tiers from the API and formats it
   */
  private fetchOBTHoldingTiers() {
    Vue.prototype.$api
      .get("/api/proxy", "/api/v1/admin/holdingtiersconfig")
      .then((response: any) => {
        this.obtHoldingTiers = JSON.stringify(response.data, null, "    ");
      });
  }

  /**
   * 🚀 Saves the updated OBT holding tiers on the API
   */
  private saveOBTHoldingTiers() {
    this.isSavingTiers = true;
    Vue.prototype.$api
      .post(
        "/api/proxy",
        "/api/v1/admin/holdingtiersconfig",
        JSON.parse(this.obtHoldingTiers)
      )
      .then((response: any) => {
        this.showSuccessSnackbar(
          "OBT holding tiers were successfully updated!"
        );
        this.fetchOBTHoldingTiers();
      })
      .finally(() => (this.isSavingTiers = false));
  }
}
