import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class PercentIndicator extends Vue {

  /**
   * Function which determines the indicator color based on its value
   */
  public getIndicatorColor(val: number) {
    if (val > 0) return 'green--text';
    if (val < 0) return 'red--text';
    return 'grey--text';
  }

  /**
   * Returns the indicator display string based on its value
   */
  public getIndicatorDisplayValue(val: number, showSign: boolean = true, showPercentage: boolean = true): string {
    if (val > 0) return (showSign ? '+' : '') + val.toFixed(2) + (showPercentage ? '%' : '');
    if (val < 0) return (showSign ? '-' : '') + (Number(val) * -1).toFixed(2) + (showPercentage ? '%' : '');
    if (val === 0) return val.toFixed(2) + (showPercentage ? '%' : '');
    return '';
  }
} 