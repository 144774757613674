import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify, { iconfont: 'mdi' });

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3476EB',
        button: '#FFEA80',
        header: '#2B044E',
        text: '#3A3A3A',
        label: '#919191',
        background: '#F9F9F9',
        chartGridColor: "#f3f3f3",
        chartGridBaseLineColor: "#d1d1d1",

        // Text
        chartLabel: "#b5b5b5",
      }
    }
  },
  icons: {
    values: {
      darkMode: '/assets/moon'
    }
  }
});
