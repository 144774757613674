var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-1",attrs:{"align":"center"}},[_c('RefreshIndicator',{attrs:{"lastRefreshTime":_vm.lastDataRefresh,"loading":_vm.areInactiveBotsFetched},on:{"refreshData":function($event){return _vm.$store.dispatch('fetchInactiveBots')}}})],1),_c('v-row',{staticClass:"mt-6"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"id":"inactive-bots-table","headers":_vm.headers,"items":_vm.inactiveBots,"items-per-page":15,"search":_vm.search,"loading":_vm.areInactiveBotsFetched,"loading-text":"Loading Archived Bots..."},on:{"click:row":_vm.onRowClicked},scopedSlots:_vm._u([{key:"item.last_trade_attempt",fn:function(ref){
var item = ref.item;
return [_c('BotActivityStatus',{attrs:{"lastTradeAttempt":item.last_trade_attempt,"candlestickSize":item.candles,"enabled":false}})]}},{key:"item.activated",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.activated)+" days ago")])]}},{key:"item.roi",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getIndicatorColor(item.roi * 100)},[_vm._v(" "+_vm._s(_vm.getIndicatorDisplayValue(item.roi * 100))+" ")])]}},{key:"item.current_balance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(item.current_balance.toFixed(2)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }