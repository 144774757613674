import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import SubscriptionTier from "@/utility/subscriptionTier";
import camelcaseKeys from "camelcase-keys";
import { showSuccessSnackbar } from "@/utils/snackbarUtils";
import snakecaseKeys from "snakecase-keys";

/**
 * Module which aims to combine logic for subscription tiers
 */
@Module
export default class SubscriptionTiersModule extends VuexModule {
  // 📦 The available subscription tiers
  public subscriptionTiers: SubscriptionTier[] = [];

  // 📦 Subscription types
  public get subscriptionTypes(): string[] {
    return this.subscriptionTiers.map((tier: SubscriptionTier) => {
      return tier.sub;
    });
  }

  // 📦 Subscription tiers which are allowed to be switched
  public get switchableSubscriptionTiers(): string[] {
    return this.subscriptionTiers.map((tier: SubscriptionTier) => tier.sub);
  }

  /**
   * 👾 Mutation which sets the newly fetched subscription tiers
   * @param newSubscriptionTiers
   */
  @Mutation
  public setSubscriptionTiers(newSubscriptionTiers: SubscriptionTier[]) {
    this.subscriptionTiers = newSubscriptionTiers;
  }

  /**
   * 🚀 Fetches the subscription tiers available
   */
  @Action
  public fetchSubscriptionPlans() {
    Vue.prototype.$api
      .get("/api/proxy", "/api/v1/admin/subscriptionconfig")
      .then((response: any) => {
        let formattedResponse = (camelcaseKeys(response.data) as any[]).flatMap(
          (tier: any) => {
            return new SubscriptionTier(
              tier.sub,
              tier.description,
              tier.allowedBots,
              tier.maxTotalInUse,
              tier.paymentFees,
              tier.pricePerMonth,
              tier.benefits,
              tier.priceIds,
              tier.payments,
              tier.freePro,
              tier.preDate,
              tier.isHidden,
              tier.pctPerTrade,
              tier.minAmountTrade,
              tier.noticeDurationInSec
            );
          }
        );

        this.context.commit("setSubscriptionTiers", formattedResponse);
      });
  }

  /**
   * 🚀 Saves the modified subsciption plans to the API
   * @param subscritionPlans 
   */
  @Action
  public async saveSubscriptionPlans(subscritionPlans: SubscriptionTier[]) {
    return await Vue.prototype.$api.post(
      "/api/proxy",
      "/api/v1/admin/subscriptionconfig",
      snakecaseKeys(
        subscritionPlans.map((tier: SubscriptionTier) => {
          tier.benefits = JSON.parse(tier.formattedBenefits);
          return tier;
        })
      )
    ).then((response: any) => {
      showSuccessSnackbar("Your changes have been saved successfully");
      this.context.dispatch("fetchSubscriptionPlans")
    });
  }

  /**
   * 🚀 Updates the selected user's subscription tier
   */
  @Action
  public updateUserSubscriptionTier(updateRequest: any) {
    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/usersubscriptions", {
        user: updateRequest.email,
        free_pro: updateRequest.freePro,
        policy_id: updateRequest.planToUpgradeTo,
        subscription_id: updateRequest.stripeSubscriptionId
      })
      .then((response: any) => {
        showSuccessSnackbar(
          "The user's subscription tier was changed successfully"
        );
      });
  }
}
