


















import Vue from "vue";
import { Prop, Component, PropSync } from "vue-property-decorator";

@Component
export default class ConfirmationDialog extends Vue {
  @Prop({ default: undefined })
  public title?: string;

  @Prop({ default: undefined })
  public message?: string;

  @PropSync("showDialog", { type: Boolean })
  public show!: boolean;

  @Prop({ default: "Yes" })
  public confirmationButtonText!: string;

  @Prop({ default: "No" })
  public declineButtonText!: string;

  private confirmationGiven() {
    this.show = false;
    this.$emit("confirmationGiven");
  }
}
