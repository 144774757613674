
import Vue from 'vue';
import Component from 'vue-class-component';
import * as permissionCheckerUtils from "@/utils/permissionChecker";

@Component
export default class PermissionCheckerMixin extends Vue {

  /**
   * 🔬 Checks whether a certain functionality is available for the current user 
   */
  public isFunctionalityAllowedForUser(page: string, permission: string) {
    return permissionCheckerUtils.isFunctionalityAllowedForUser(page, permission);
  }
}
