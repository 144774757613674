






























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import dateFormatterMixin from "@/mixins/dateFormatterMixin";

@Component({
  mixins: [dateFormatterMixin]
})
export default class RefreshIndicator extends Vue {
  // 📦 Time since the last refresh has happened
  @Prop()
  public lastRefreshTime?: Date | null;

  // 🚩 Flag for whether the component is loading
  @Prop({ default: false })
  public loading?: boolean;

  // 👂 Interval handler that updates the indicator periodically
  private indicatorUpdater: any;

  mounted() {
    // Attach interval handler
    this.indicatorUpdater = setInterval(() => {
      const temp = this.lastRefreshTime;
      this.lastRefreshTime = null;
      this.lastRefreshTime = temp;
    }, 5000);
  }

  beforeDestroy() {
    // Cleat interval handler
    clearInterval(this.indicatorUpdater);
  }
}
