import AdminRoleWithPermissions from "@/utility/admin-users/adminRoleWithPermissions";
import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { showSuccessSnackbar } from "@/utils/snackbarUtils";

/**
 * 🧩 Module which aims to combine logic for the admin roles page
 */
@Module
export default class adminRolesModule extends VuexModule {
  // 📦 The cached admin roles with their permissions
  public adminRolesWithPermissions: AdminRoleWithPermissions[] = [];

  // 📦 All the available admin roles
  public get adminUserRoles(): string[] {
    return this.adminRolesWithPermissions.map((roleWithPermissions: AdminRoleWithPermissions) => roleWithPermissions.role);
  }

  /**
   * 👾 Mutation for setting the newly fetched admin roles
   * @param newAdminRoles
   */
  @Mutation
  private setAdminRoles(newAdminRoles: AdminRoleWithPermissions[]) {
    this.adminRolesWithPermissions = newAdminRoles;
  }

  /**
   * 🚀 Fetches all the admin roles from the API
   */
  @Action
  public async fetchAdminRoles() {
    const response = await Vue.prototype.$api.get("/api/roles");
    this.context.commit("setAdminRoles", response.data);
  }

  /**
 * 🚀 Updates the an admin role on the API
 */
  @Action
  public async updateAdminRolePermissions(adminRoleWithPermissions: AdminRoleWithPermissions) {
    const response = await Vue.prototype.$api.post("/api/updaterolepermissions", null, {
      role: adminRoleWithPermissions.role,
      permissions: adminRoleWithPermissions.permissions,
    });
    this.context.dispatch("fetchAdminRoles");
    showSuccessSnackbar("Permissions for " + adminRoleWithPermissions.role + " role were sucessfully updated");
  }

  /**
   * 🚀 Creates a new admin role on the API
   */
  @Action
  public async createNewAdminRole(newAdminRoleWithPermissions: AdminRoleWithPermissions) {
    const response = await Vue.prototype.$api.post("/api/createrole", null, {
      role: newAdminRoleWithPermissions.role,
      permissions: newAdminRoleWithPermissions.permissions,
    });
    this.context.dispatch("fetchAdminRoles");
  }

  /**
   * 🚀 Deletes an existing admin role from the API
   */
  @Action
  public async deleteAdminRole(roleToDelete: string) {
    const response = await Vue.prototype.$api.delete("/api/deleterole", null, {
      role: roleToDelete
    });
    this.context.dispatch("fetchAdminRoles");
    showSuccessSnackbar(roleToDelete + " role was sucessfully deleted");
  }
}