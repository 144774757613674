









































































import Base from "../Base.vue";
import { Component } from "vue-property-decorator";
import DateFormatter from "@/mixins/dateFormatterMixin";
import AdminUser from "@/utility/admin-users/adminUser";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import CreateNewAdminDialog from "./CreateNewAdminDialog.vue";
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";

@Component({
  components: { ConfirmationDialog, CreateNewAdminDialog },
  mixins: [DateFormatter, PermissionCheckerMixin]
})
export default class AdminUsers extends Base {
  // 📦 The headers that will be applied on the admin users table
  private adminUsersTableHeaders = [
    { text: "Username", value: "username" },
    { text: "Role", value: "role" },
    { text: "Created On", value: "timeAdded" },
    { text: "", sortable: false, value: "actions" }
  ];

  // 🚩 Flag for whether the delete admin user dialog should be shown
  private showDeleteAdminUserDialog: boolean = false;

  // 🚩 Flag for whether the create admin user dialog should be shown
  private showCreateAdminUserDialog: boolean = false;

  // 📦 The selected user for deletion
  private selectedUserToDelete: AdminUser | null = null;

  private get adminUserRoles(): string[] {
    return this.$store.getters.adminUserRoles;
  }
  // 📦 The current admin users
  private get adminUsers(): AdminUser[] {
    return this.$store.state.adminUsers.adminUsers;
  }

  private get currentUser(): string {
    return this.$store.state.globalStateModule.currentUser;
  }

  protected async mounted() {
    super.mounted();
  }
}
