




































































import { Component, Watch, Prop } from "vue-property-decorator";
import BaseChart from "./charts/BaseChart.vue";
import Vue from "vue";

@Component
export default class OBTEarningChart extends BaseChart {
  // 📃 Possible time filters for the chart
  private chartFilters = {
    Day: 1,
    Week: 7,
    Month: 30,
    Quarter: 90,
    "Since Start": -1
  };

  // 📄 The possible transaction types that could be displayed
  private transactionType: string[] = ["FEE-SUBSCRIPTION", "SUBSCRIPTION", "WITHDRAW", "DEPOSIT", "REFERRAL-REWARD-CASHBACK"];

  // 📦 The selected transaction type that the chart displays
  private selectedTransactionType: string = this.transactionType[0];

  // 📄 The possible timeframes that could be chosen for the chart
  private timeFrames = [
    { text: "1 hour", value: 3600 },
    { text: "4 hours", value: 3600 * 4 },
    { text: "6 hours", value: 3600 * 6 },
    { text: "12 hours", value: 3600 * 12 },
    { text: "1 day", value: 86400 },
    { text: "7 days", value: 86400 * 7 }
  ];

  // 🚩 Flag for whether the data shown on the chart should be accumulative
  private accumulative: boolean = true;

  // 📦 The selected timeframe to which the chart is adjusted to
  private selectedTimeFrame: number = 86400;

  // 📦 The selected filter for days
  private selectedFilterInDays: number = -1;

  // 📦 Dataset to be displayed
  private dataset!: number[];

  // 📦 Dates for point on the chart
  private dateLabels!: Date[];

  /**
   * 👂 Listener used in order to determine the selected chart filter mode
   */
  @Watch("selectedFilterInDays", { immediate: true, deep: true })
  private onSelectedFilterInDaysChanged(val: number, oldVal?: number) {
    this.fetchOBTEarnings();
  }

  /**
   * 👂 Listener used in order to track the changes in the selected transaction type
   */
  @Watch("selectedTransactionType")
  private onSelectedTransactionTypeChanged(val: string, oldVal?: string) {
    this.fetchOBTEarnings();
  }

  /**
   * 👂 Listener used in order to track the changes in the selected timeframe
   */
  @Watch("selectedTimeFrame")
  private onSelectedTimeFrameChanged(val: number, oldVal?: number) {
    this.fetchOBTEarnings();
  }

  /**
   * 👂 Listener used in order to track the changes in the accumulative flag
   */
  @Watch("accumulative")
  private onAccumulativeFlagChanged(val: boolean, oldVal?: boolean) {
    this.fetchOBTEarnings();
  }

  mounted() {
    this.fetchOBTEarnings();
  }

  /**
   * 🚀 Fetches the OBT earnings from the API
   */
  private async fetchOBTEarnings() {
    let startDate =
      this.selectedFilterInDays > 0
        ? new Date().getTime() / 1000 - this.selectedFilterInDays * 86400
        : 0;

    const response = await Vue.prototype.$api.post(
      "/api/proxy",
      "/api/v1/admin/adminobtearning",
      {
        transaction_type: this.selectedTransactionType,
        start_timestamp: startDate.toFixed(0),
        time_span_in_sec: this.selectedTimeFrame,
        accumulative: this.accumulative
      }
    );

    let data = response.data.result;
    this.dateLabels = Object.keys(data).map(
      (rawDate: string) =>
        new Date(Number(rawDate) * 1000 - new Date().getTimezoneOffset() * 60)
    );
    this.dataset = Object.values(data);
    this.createChart(this.dateLabels, this.dataset, this.selectedFilterInDays);
  }

  /**
   * 🏗 The element ID of the current chart
   */
  protected chartElementId(): string {
    return "container-obt-earnings-chart";
  }

  /**
   * 🔨 Formats the data representation for the Y axis
   */
  protected formatYAxisData(
    value: number,
    index: number,
    values: number[]
  ): string {
    return value.toFixed(0);
  }

  /**
   * 🔨 Returns the datasets with their display properties that should be displayed on the chart
   */
  protected getDataSets() {
    let dataSets = [];

    // Create gradient fill
    var gradient = (this.$refs.obtEarningsChart as any)
      .getContext("2d")
      .createLinearGradient(
        0,
        0,
        0,
        (this.$refs.obtEarningsChart as any).clientHeight
      );
    gradient.addColorStop(0, "rgba(52, 118, 235, 0.86)");
    gradient.addColorStop(1, "rgba(101, 217, 147, 0.15)");

    if (this.dataset.length > 0) {
      dataSets.push({
        label: "OBT Earnings",
        data: this.dataset,
        borderColor: "#3476EB",
        backgroundColor: gradient,
        pointRadius: 1,
        borderWidth: 2
      });
    }

    return dataSets;
  }
}
