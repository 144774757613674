import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import User, { UserBotTierRank } from "@/utility/user";
import { showSuccessSnackbar } from "@/utils/snackbarUtils";
import store from "@/store";
import { GeneralEventTypes } from "../eventBus/generalEventTypes";

/**
 * 🧩 Module which aims to combine logic for the users page
 */
@Module
export default class UsersModule extends VuexModule {
  // 🚩 Flag for whether the users are fetched from the API
  public areUsersFetched: boolean = false;

  // 📦 The cached users
  public users: User[] = [];

  // 📦 The last time the user data has been refreshed
  public lastRefresh: Date | null = null;

  /**
   * 👾 Mutation which sets the flag for whether the user are fetched
   * @param areFetched
   */
  @Mutation
  private setUsersAreFetched(areFetched: boolean) {
    this.areUsersFetched = areFetched;
  }

  /**
   * 👾 Mutation for setting the newly fetched users
   * @param newUsers
   */
  @Mutation
  private setUsers(newUsers: User[]) {
    this.users = newUsers;
  }

  /**
   * 👾 Mutation for setting the last time the data was refreshed
   * @param newRefreshTime
   */
  @Mutation
  private setLastRefreshTime(newRefreshTime: Date) {
    this.lastRefresh = newRefreshTime;
  }

  /**
   * 🚀 Fetches all the users of the platform from the API
   */
  @Action
  public async fetchUsers(search: string | null) {
    this.context.commit("setUsersAreFetched", true);
    this.context.commit("setUsers", []);

    try {
      const response = await Vue.prototype.$api.get(
        "/api/proxy",
        "/api/v1/admin/users" + (search ? "?search=" + search : ""),
      );
      this.context.commit(
        "setUsers",
        (response.data as any[]).map((user: any) => {
          return new User(
            user.email,
            user.name,
            user.exchanges,
            user.bots_count,
            user.bots,
            user.status,
            user.bot_balance,
            user.balance,
            user.time_added,
            user.last_active,
            user.subscription,
            user.referrals,
            user.token,
            user.token_transactions,
            user.token.balance ? user.token.balance : 0,
            user.OTP,
            user.no_NFT_tokens,
            user.strat_tier_rank ? user.strat_tier_rank : 1
          );
        })
      );
      this.context.commit("setLastRefreshTime", new Date());
      this.context.commit("setUsersAreFetched", false);
    } catch (error) {
      this.context.commit("setUsersAreFetched", false);
    }
  }

  /**
   * 🚀 Creates a new user throught the API
   * @param userData - email + name of the new user
   */
  @Action
  private async addUser(userData: any) {
    await Vue.prototype.$api.post("/api/proxy", "/api/v1/admin/users", {
      email: userData.email,
      name: userData.name
    });
    showSuccessSnackbar("The user was successfully added");
    store.dispatch("publishEvent", GeneralEventTypes.UpdateInvitations);
  }

  /**
   * 🚀 Deletes a specific user based on their email
   * @param email
   */
  @Action
  private async deleteUser(email: string) {
    await Vue.prototype.$api.post("/api/proxy", "/api/v1/admin/deleteuser", {
      email
    });
    showSuccessSnackbar("The user was successfully deleted");

    // Remove user locally
    const indexToDelete = this.users.findIndex((user: User) => {
      return user.email === email;
    });
    const modified = this.users;
    modified.splice(indexToDelete, 1);
    this.context.commit("setUsers", modified);
  }

  /**
   * 🚀 Updates the user's bot tier rank so they can access different tier of bots
   * @param data 
   */
  @Action
  public async updateUserBotTierRank(data: any) {
    Vue.prototype.$api
      .put("/api/proxy", "/api/v1/admin/users", {
        email: data.email,
        strat_tier_rank: UserBotTierRank[data.tier]
      })
      .then((response: any) =>
        showSuccessSnackbar(
          data.email + " was successfully upgraded to " + data.tier
        )
      );
  }
}
