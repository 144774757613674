var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-1",attrs:{"align":"center"}},[_c('RefreshIndicator',{attrs:{"lastRefreshTime":_vm.lastDataRefreshTime,"loading":_vm.areUsersFetched},on:{"refreshData":function($event){return _vm.$store.dispatch('fetchUsers', _vm.searchedUser)}}}),_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-layout',{attrs:{"d-flex":"","justify-end":""}},[_c('v-btn',_vm._g(_vm._b({staticClass:"secondary-button",attrs:{"outlined":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Add User ")],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline lighten-2"},[_vm._v("Add User")]),_c('v-card-text',{staticClass:"pl-10 pr-10"},[_c('v-row',{staticClass:"mt-6"},[_c('v-text-field',{attrs:{"id":"email-input","outlined":"","label":"E-mail","required":"","type":"text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"id":"name-input","outlined":"","label":"Name","required":"","type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"muted","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("CANCEL")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$store.dispatch('addUser', { email: _vm.email, name: _vm.name });
              _vm.email = '';
              _vm.name = '';
              _vm.dialog = false;}}},[_vm._v(" CONFIRM ")])],1)],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search User...","single-line":"","hide-details":""},model:{value:(_vm.searchedUser),callback:function ($$v) {_vm.searchedUser=$$v},expression:"searchedUser"}})],1),_c('v-row',{staticClass:"mt-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":15,"search":_vm.searchedUser,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.areUsersFetched,"loading-text":"Loading Users...","dense":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.bots_count",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{staticClass:"clickable pa-0",attrs:{"color":"white"},on:{"click":function($event){_vm.showBotsDialog = true;
            _vm.selectedUserBots = item.bots;
            _vm.username = item.name;
            _vm.userEmail = item.email;}}},[_vm._v(" "+_vm._s(item.bots_count)+" bots "),_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}},{key:"item.subscription",fn:function(ref){
            var item = ref.item;
return [_c('v-layout',{attrs:{"d-flex":"","align-center":""}},[_c('span',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(item.subscription))]),(_vm.isFunctionalityAllowedForUser('users', 'upgrade'))?_c('v-btn',{attrs:{"icon":"","color":"primary lighten-2","small":""},on:{"click":function($event){_vm.selectedUser = item;
              _vm.showUpgradeUserDialog = true;}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-arrow-up-bold-circle")])],1):_vm._e()],1)]}},(_vm.isFunctionalityAllowedForUser('users', 'sensitive'))?{key:"item.available_token_balance",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{staticClass:"clickable pa-0",attrs:{"color":"white"},on:{"click":function($event){_vm.selectedUser = item;
            _vm.showUserWalletDialog = true;}}},[_vm._v(" "+_vm._s(Number(item.available_token_balance).toFixed(6))+" OBT "),_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}:null,{key:"item.time_added",fn:function(ref){
            var item = ref.item;
return [_c('tooltip-label',{attrs:{"label":_vm.timeSince(new Date(item.time_added * 1000), false),"tooltip":_vm.formatDateFromAPI(item.time_added)}})]}},{key:"item.botTierRank",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{attrs:{"items":item.getBotTiers(),"value":item.getBotTierRank()},on:{"change":function($event){return _vm.$store.dispatch('updateUserBotTierRank', {
              email: item.email,
              tier: $event
            })}}})]}},{key:"item.number_of_NFTs",fn:function(ref){
            var item = ref.item;
return [(item.number_of_NFTs == 0)?_c('span',[_vm._v(" "+_vm._s(item.number_of_NFTs)+" ")]):_c('v-chip',{staticClass:"clickable px-0",attrs:{"color":"white"},on:{"click":function($event){_vm.showUserNFTDialog = true;
            _vm.selectedUser = item;}}},[_vm._v(" "+_vm._s(item.number_of_NFTs)+" "),_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}},{key:"item.referrals",fn:function(ref){
            var item = ref.item;
return [(item.referrals.length == 0)?_c('span',[_vm._v(" "+_vm._s(item.referrals.length)+" ")]):_c('v-chip',{staticClass:"clickable px-0",attrs:{"color":"white"},on:{"click":function($event){_vm.showReferralsDialog = true;
            _vm.selectedUser = item;}}},[_vm._v(" "+_vm._s(item.referrals.length)+" "),_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}},{key:"item.last_active",fn:function(ref){
            var item = ref.item;
return [_c('tooltip-label',{attrs:{"label":_vm.timeSince(
              new Date(new Date().getTime() - item.last_active * 1000),
              false
            ),"tooltip":_vm.formatDateFromAPI(new Date().getTime() / 1000 - item.last_active)}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.isFunctionalityAllowedForUser('users', 'delete'))?_c('v-btn',{attrs:{"small":"","outlined":"","color":"red","title":"Deactivate user"},on:{"click":function($event){_vm.emailToDelete = item.email;
            _vm.dialogDelete = true;}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle-outline")])],1):_vm._e()]}},{key:"item.bot_balance",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s("$" + item.bot_balance))])]}}],null,true)})],1),(_vm.isFunctionalityAllowedForUser('users', 'delete'))?_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline lighten-2"},[_vm._v("Deactivate user")]),_c('v-card-text',{staticClass:"pl-10 pr-10"},[_vm._v(" Are you sure you want to deactivate "+_vm._s(_vm.emailToDelete)+"? They will no longer be able to use OB Trader. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"muted","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("CANCEL")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$store.dispatch('deleteUser', _vm.emailToDelete);
            _vm.dialogDelete = false;}}},[_vm._v(" CONFIRM ")])],1)],1)],1):_vm._e(),_c('ReferralsDialog',{attrs:{"showDialog":_vm.showReferralsDialog,"user":_vm.selectedUser},on:{"update:showDialog":function($event){_vm.showReferralsDialog=$event},"update:show-dialog":function($event){_vm.showReferralsDialog=$event}}}),_c('UserBotsDialog',{attrs:{"showDialog":_vm.showBotsDialog,"bots":_vm.selectedUserBots,"email":_vm.userEmail,"username":_vm.username},on:{"update:showDialog":function($event){_vm.showBotsDialog=$event},"update:show-dialog":function($event){_vm.showBotsDialog=$event}}}),_c('ChangeUserSubscriptionPlanDialog',{attrs:{"showDialog":_vm.showUpgradeUserDialog,"user":_vm.selectedUser},on:{"update:showDialog":function($event){_vm.showUpgradeUserDialog=$event},"update:show-dialog":function($event){_vm.showUpgradeUserDialog=$event}}}),_c('UserWalletDialog',{attrs:{"showDialog":_vm.showUserWalletDialog,"user":_vm.selectedUser},on:{"update:showDialog":function($event){_vm.showUserWalletDialog=$event},"update:show-dialog":function($event){_vm.showUserWalletDialog=$event}}}),_c('NFTsDialog',{attrs:{"showDialog":_vm.showUserNFTDialog,"user":_vm.selectedUser},on:{"update:showDialog":function($event){_vm.showUserNFTDialog=$event},"update:show-dialog":function($event){_vm.showUserNFTDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }