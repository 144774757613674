import EventEmitter from "eventemitter3";
import axios, { AxiosError } from "axios";
import store from "@/store";

/**
 * 🎨 Possible options for emitting default behaviour
 */
export enum Emit {
  None = 0,
  Error = 1,
  Success = 2,
  All = 3 // Full BitMask
}

/**
 * 🎨 Possible API events that the emitter can emit
 */
export enum ApiEvents {
  Success = "success",
  Error = "error",
  All = "all"
}

/**
 * 🏗 Helper class which aims to abstract API handling
 */
export default class ApiHelper extends EventEmitter<
  ApiEvents.Success | ApiEvents.Error | ApiEvents.All
> {
  public async wrap<R>(f: () => Promise<R>, emit: Emit): Promise<R> {
    let data;
    try {
      // Await here so we can catch the error (might even work w/o bc of closures but don't know for sure)
      data = await f();
      if (emit & Emit.Success) this.emit(ApiEvents.Success, data);
      data = Promise.resolve(data);
    } catch (e) {
      if (emit & Emit.Error) this.emit(ApiEvents.Error, e);
      data = Promise.reject(e);
    }
    if (emit & Emit.All) this.emit(ApiEvents.All, data);
    return data;
  }

  /**
   * 🔨 Function which abstracts the @GET method for all API calls
   * @param url - the url on which the call should be executed on
   * @param serverUrl - an optional url that can be url of the normal backend
   * @param parameters - query parameters which are passed with the API call (Optional)
   */
  public async get(
    url: string,
    serverUrl?: any,
    parameters?: any,
    emit = Emit.All
  ) {
    return this.wrap(
      () =>
        axios.get(url, {
          params: parameters,
          headers: {
            "X-Path-Forward": serverUrl,
            "X-Server-Forward": (store.state as any).globalStateModule
              .selectedServer
          }
        }),
      emit
    );
  }

  /**
   * 🔨 Function which abstracts the @POST method for all API calls
   * @param url - the url on which the call should be executed on
   * @param serverUrl - an optional url that can be url of the normal backend
   * @param data - the data passed to the backend as body
   */
  public async post(url: string, serverUrl: any, data: any, emit = Emit.All) {
    return this.wrap(
      () =>
        axios.post(url, data, {
          headers: {
            "X-Path-Forward": serverUrl,
            "X-Server-Forward": (store.state as any).globalStateModule
              .selectedServer
          }
        }),
      emit
    );
  }

  /**
   * 🔨 Function which abstracts the @DELETE method for all API calls
   * @param url - the url on which the call should be executed on
   * @param serverUrl - an optional url that can be url of the normal backend
   * @param data - the data passed to the backend as body
   */
  public async delete(
    url: string,
    serverUrl?: string,
    data?: any,
    emit = Emit.All
  ) {
    return this.wrap(
      () =>
        axios.delete(url, {
          headers: {
            "X-Path-Forward": serverUrl,
            "X-Server-Forward": (store.state as any).globalStateModule
              .selectedServer
          },
          data: data
        }),
      emit
    );
  }

  /**
   * 🔨 Function which abstracts the @PUT method for all API calls
   * @param url - the url on which the call should be executed on
   * @param serverUrl - an optional url that can be url of the normal backend
   * @param data - the updated information that is sent back to the backend
   */
  public async put(url: string, serverUrl: string, data: any, emit = Emit.All) {
    return this.wrap(
      () =>
        axios.put(url, data, {
          headers: {
            "X-Path-Forward": serverUrl,
            "X-Server-Forward": (store.state as any).globalStateModule
              .selectedServer
          }
        }),
      emit
    );
  }
}
