import Vue from "vue";
import VueRouter, { NavigationGuard, Route, RouteConfig } from "vue-router";
import Login from "@/views/Login.vue";
import Strategy from "@/views/Strategy.vue";
import Stats from "@/views/Stats.vue";
import UsersBase from "@/views/users/UsersBase.vue";
import BotsBase from "@/views/bots/BotsBase.vue";
import Analytics from "@/views/analytics/Analytics.vue";
import Subscriptions from "@/views/subscriptions/Subscriptions.vue";
import BotLogs from "@/views/bots/BotLogs.vue";
import Logs from "@/views/logs/Logs.vue";
import store from "@/store/index";
import AdminUsersAndRolesWrapper from "@/views/admin-users/AdminUsersAndRolesWrapper.vue";
import { isPageAllowedForUser } from "@/utils/permissionChecker";
import { getFirstAvailableUrlForUser} from "@/utils/routerUtils";
import Markets from "@/views/markets/Markets.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    beforeEnter(to: any, from: any, next: any) {
      // Check whether the user is authenticated if so navigate to the users page
      if ((store.state as any).globalStateModule.isAuthorized) {
        next(getFirstAvailableUrlForUser());
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/users",
    name: "Users",
    component: UsersBase,
    beforeEnter(to: Route, from: Route, next: any) {
      next(isPageAllowedForUser("users"));
    }
  },
  {
    path: "/models",
    name: "Models",
    component: Strategy,
    beforeEnter(to: Route, from: Route, next: any) {
      next(isPageAllowedForUser("models"));
    }
  },
  {
    path: "/markets",
    name: "Markets",
    component: Markets,
    beforeEnter(to: Route, from: Route, next: any) {
      next(isPageAllowedForUser("markets"));
    }
  },
  {
    path: "/stats",
    name: "Statistics",
    component: Stats,
    beforeEnter(to: Route, from: Route, next: any) {
      next(isPageAllowedForUser("stats"));
    }
  },
  {
    path: "/bots",
    name: "Bots",
    component: BotsBase,
    beforeEnter(to: Route, from: Route, next: any) {
      next(isPageAllowedForUser("bots"));
    }
  },
  {
    path: "/bots/:id",
    name: "BotLogs",
    props: true,
    component: BotLogs,
    beforeEnter(to: Route, from: Route, next: any) {
      next(isPageAllowedForUser("bots"));
    }
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: Analytics,
    beforeEnter(to: Route, from: Route, next: any) {
      next(isPageAllowedForUser("analytics"));
    }
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
    beforeEnter(to: Route, from: Route, next: any) {
      next(isPageAllowedForUser("subscriptions"));
    }
  },
  {
    path: "/logs",
    name: "Logs",
    component: Logs,
    beforeEnter(to: Route, from: Route, next: any) {
      next(isPageAllowedForUser("logs", "tree"));
    }
  },
  {
    path: "/admin-users",
    name: "Admin Users & Roles",
    component: AdminUsersAndRolesWrapper,
    beforeEnter(to: Route, from: Route, next: any) {
      next(isPageAllowedForUser("superadmin"));
    }
  }
];

const router = new VueRouter({
  routes
});

export default router;
