/**
 * 🔨 Functionality that enabled to define a generic parameter comparator
 * @param sortingParameter 
 * @param items 
 * @param index 
 * @param isDesc 
 * @returns 
 */
export function sortItems(
  sortingParameter: string,
  items: any[],
  index: string[],
  isDesc: boolean[]
) {
  const dateCompare = (a: any, b: any) =>
    new Date(a).getTime() - new Date(b).getTime();

  items.sort((a, b) => {
    if (index[0] === sortingParameter) {
      return dateCompare(a[sortingParameter], b[sortingParameter]);
    } else {
      return a[index[0]] < b[index[0]] ? 1 : -1;
    }
  });

  if (!isDesc[0]) {
    return items.reverse();
  }

  return items;
}