











































































































































































































































































































import { Component } from "vue-property-decorator";
import Base from "@/views/Base.vue";
import User from "@/utility/user";
import RefreshIndicator from "@/components/general/RefreshIndicator.vue";
import DateFormatterMixin from "@/mixins/dateFormatterMixin";
import TooltipLabel from "@/components/general/TooltipLabel.vue";
import UserBotsDialog from "./components/UserBotsDialog.vue";
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";

@Component({
  components: {
    RefreshIndicator,
    TooltipLabel,
    UserBotsDialog,
    ChangeUserSubscriptionPlanDialog: () =>
      import("./components/ChangeUserSubscriptionPlanDialog.vue"),
    ReferralsDialog: () => import("./components/ReferralsDialog.vue"),
    UserWalletDialog: () => import("./components/UserWalletDialog.vue"),
    NFTsDialog: () => import("./components/NFTsDialog.vue")
  },
  mixins: [DateFormatterMixin, PermissionCheckerMixin]
})
export default class Users extends Base {
  data() {
    return {
      dialog: false,
      sortBy: "bots_count",
      sortDesc: true
    };
  }

  // 📦 The currently searched user in the search box
  private searchedUser: string = "";

  // 📦 The name of the user that will be added
  private name: string = "";

  // 📦 The email of the user that will be added
  private email: string = "";

  // 📦 The table headers
  private headers = [
    { text: "Name", value: "name" },
    { text: "Email", value: "email" },
    { text: "Exchanges", value: "exchanges" },
    { text: "Bots", value: "bots_count" },
    { text: "Bot Balance", value: "bot_balance" },
    { text: "Subscription Tier", value: "subscription" },
    { text: "Bot Tier", value: "botTierRank" },
    { text: "Referrals", value: "referrals" },
    { text: "Joined", value: "time_added" },
    { text: "Last Active", value: "last_active" },
    { text: "Actions", value: "actions" }
  ];

  // 📦 Email of the user that should be deleted
  private emailToDelete: string | null = null;

  // 🚩 Flag for whether the dialog for deleting an user should be shown
  private dialogDelete: boolean = false;

  // 🚩 Flag for whether the user bots dialog should be shown
  private showBotsDialog: boolean = false;

  // 🚩 Flag for whether the user referrals dialog should be shown
  private showReferralsDialog: boolean = false;

  // 📦 The bot ids of the selected user
  private selectedUserBots: any;

  // 📦 The username that will be displayed in the bots dialog
  private username: string | null = null;

  // 📦 The user email that will be displayed in the bots dialog
  private userEmail: string | null = null;

  // 📦 The currently selected user from the table
  private selectedUser: User | null = null;

  // 🚩 Flag for whether the upgrade user dialog should be shown
  private showUpgradeUserDialog: boolean = false;

  // 🚩 Flag for whether the user wallet dialog should be shown
  private showUserWalletDialog: boolean = false;

  // 🚩 Flag for whether the user NFT dialog should be shown
  private showUserNFTDialog: boolean = false;

  // 🚩 Flag for whether the users are loading
  private get areUsersFetched(): boolean {
    return this.$store.state.users.areUsersFetched;
  }

  // 📦 The currently displayed users
  private get users(): User[] {
    return this.$store.state.users.users;
  }

  // 📦 The last time the user data was refreshed
  private get lastDataRefreshTime(): Date | null {
    if (this.$store.state.users.lastRefresh != null) {
      return new Date(this.$store.state.users.lastRefresh);
    }
    return null;
  }

  protected async mounted() {
    await super.mounted();
    if (
      new PermissionCheckerMixin().isFunctionalityAllowedForUser(
        "subscriptions",
        "view"
      )
    ) {
      this.$store.dispatch("fetchSubscriptionConfiguration");
      this.$store.dispatch("fetchSubscriptionPlans");
    }

    if (
      new PermissionCheckerMixin().isFunctionalityAllowedForUser(
        "users",
        "sensitive"
      )
    ) {
      this.headers.splice(7, 0, {
        text: "Wallet Balance",
        value: "available_token_balance"
      });
      this.headers.splice(8, 0, {
        text: "NFTs Owned",
        value: "number_of_NFTs"
      });
    }
  }
}
