














import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import DateFormatterMixin from "@/mixins/dateFormatterMixin";
import {
  BotStatus,
  getBotStatus,
  getColorBasedBotStatus
} from "@/utils/botStatusUtils";

@Component({
  mixins: [DateFormatterMixin]
})
export default class BotActivityStatus extends Vue {
  // 📦 The last trade attempt the bot has
  @Prop()
  public lastTradeAttempt!: number;

  // 📦 The candlestick size of the bot
  @Prop()
  public candlestickSize!: string;

  // 🚩 Flag for whether the bot is active or archived
  @Prop()
  public enabled!: boolean;

  // 📦 The status of the selected bot
  private get botStatus(): BotStatus {
    return getBotStatus(
      this.lastTradeAttempt,
      this.candlestickSize,
      this.enabled
    );
  }

  // 📦 The color of the small circle indicator in front the bot status
  private get botStatusColor(): string {
    return getColorBasedBotStatus(this.botStatus);
  }
}
