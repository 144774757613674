/**
 * 🎨 Function which maps currency to color
 * @param currency 
 * @returns 
 */
export default function mapCurrencyToColor(currency: string): string {
  switch(currency) {
    case "BTC": return "#FF9900";
    case "USDT": return "#0ECC8D";
    case "LTC": return "#949494";
    case "ETH": return "#497493";
    case "XMR": return "#FC6621";
    case "BNB": return "#F3BA2F";
    case "DOT": return "#E5918F";
    case "BUSD": return "#F0B90B";
    case "XBT": return "#FF9900";
    case "EUR": return "#9FCDCD";
    case "ETC": return "#669073";
    case "ADA": return "#000033";
    case "XRP": return "#00AAE4";
    case "BCH": return "#478558";
    case "TRX": return "#FF0013";
    case "LINK": return "#2A5ADA";
    case "XLM": return "#000000";
    case "VET": return "#15BDFF";
    case "UNI": return "#FF007A";
    default: return "";
  }
}