import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../../store';
import { GeneralEventTypes } from './generalEventTypes';

/**
 * Module which aims to provide an event bus throughout the web app
 */
@Module
export default class EventBus extends VuexModule {
  /**
   * The event that should be handled
   */
  public event: GeneralEventTypes | null = null;

  /**
   * Mutation which updates the pending event
   * @param event 
   */
  @Mutation
  public updateEvent(event: GeneralEventTypes) {
    this.event = event;

    this.event = null
  }

  /**
   * Publishes an event that will be sent to all listeners
   * @param event 
   */
  @Action
  public publishEvent(event: GeneralEventTypes) {
    store.commit("updateEvent", event);
  }
}

