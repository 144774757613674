var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"header-color":"primary","headers":_vm.tradeHistoryHeaders,"items":_vm.tradeHistory ? _vm.tradeHistory : [],"options":_vm.paginationTradeLog,"custom-sort":_vm.sortByDate,"loading-text":"Loading trade history..."},on:{"update:options":function($event){_vm.paginationTradeLog=$event}},scopedSlots:_vm._u([{key:"header.balance",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"mr-1"},[_vm._v("Ending Balance")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateFromAPI(item.date)))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getTypeColor(item.type)},[_vm._v(_vm._s(item.type))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDisplayNumber(item.price))+" "+_vm._s(item.priceCurrency)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount)+" "+_vm._s(item.currency))])]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"d-flex":"","align-center":""}},[_c('span',{class:item.isLimitOrder ? 'mr-1' : ''},[_vm._v(" "+_vm._s(Number(item.fee).toFixed(8))+" "+_vm._s(item.fee_asset)+" ")])])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"d-flex":"","align-center":""}},[_c('span',[_vm._v(" "+_vm._s(item.balance.toFixed(2) + " " + item.priceCurrency)+" ")]),(!!item.change)?_c('span',{class:_vm.getIndicatorColor(item.change) + ' text-caption ml-2 clickable'},[_c('tooltip-label',{attrs:{"label":_vm.getIndicatorDisplayValue(item.change, true),"tooltip":"Change since last trade"}})],1):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }