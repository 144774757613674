

















import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";

interface Server {
  id?: string;
  host?: string;
  url?: string;
  name: string;
}

@Component
export default class ServerSelector extends Vue {
  @PropSync("selectedId", {type: String})
  private selectedId: string = '';

  private serverList: Server[] | null = null;
  private selected: Server | null = null;
  private hint: string = "";

  @Watch("selected")
  onSelectedChanged(val: Server, oldVal: Server) {
    this.selectedId = val.id || val.name;
    this.selected = val;
    this.showSelectedServerHint();
  }

  mounted() {
    this.serverList = this.convertReceivedServerObjects(
      this.$store.state.globalStateModule.serverList
    );
    // Remove current server from selection
    const selected = this.getGlobalSelectedServer();

    // TODO: should filter once selected has been found?
    if (selected) // Filter if possible
      this.serverList = this.serverList.filter((v) => v.id && v.id !== selected.id)

    this.showSelectedServerHint();
  }

  /**
   * Returns the selected server
   */
  private getGlobalSelectedServer(): Server | null {
    var selectedServer = this.serverList?.filter(
      (element: Server, index: number, array: Server[]) => {
        return element.id == this.$store.state.globalStateModule.selectedServer;
      }
    )[0];
    return !!selectedServer ? selectedServer : null;
  }

  /**
   * Properly maps the raw response to server objects with id
   */
  private convertReceivedServerObjects(rawServers: any): any[] {
    var convertedServerObjects = [];
    for (const k in rawServers) {
      convertedServerObjects.push({
        id: k,
        ...rawServers[k],
      });
    }
    return convertedServerObjects;
  }

  /**
   * Sets a hint about the selected server and its properties
   */
  private showSelectedServerHint() {
    this.hint = `${(this.selected?.id || "").substring(0, 5)} (${
      this.selected?.host || this.selected?.url || ""
    })`;
  }
}
