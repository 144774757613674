import Vue from 'vue'
import Vuex from 'vuex'
import globalStateModule from '@/modules/GlobalStateModule';
import eventBus from "@/modules/eventBus/eventBus";
import createPersistedState from "vuex-persistedstate"
import subscriptionTiers from "@/modules/subscription-tiers/subscriptionTiersModule";
import globalSnackbar from "@/modules/global-snackbar/snackbarModule";
import users from "@/modules/users/usersModule";
import bots from "@/modules/bots/botsModule";
import adminUsers from "@/modules/admin-users/adminUsersModule";
import adminRoles from "@/modules/admin-users/adminRolesModule";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    globalStateModule,
    eventBus,
    subscriptionTiers,
    globalSnackbar,
    users,
    bots,
    adminUsers,
    adminRoles,
  },
  plugins: [
    createPersistedState()
  ]
})
