import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class NumberFormatter extends Vue {

  /**
   * 👁‍🗨 Formats the display value for a number in the following format:
   * XXX,XXX.XX
   * X,XXX.XX
   * 0.XXXX
   * 0.00XXXX
   */
  public formatDisplayNumber(val: number): string {
    if (val > 0 && val <= 1) {
      return val < 0.01
        ? val.toLocaleString("en-US", { maximumFractionDigits: 6, minimumFractionDigits: 6 })
        : val.toLocaleString("en-US", { maximumFractionDigits: 4, minimumFractionDigits: 4 });
    }
    return val.toLocaleString("en-US", { maximumFractionDigits: 2 });
  }
}
