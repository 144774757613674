


































































import Base from "../Base.vue";
import { Component } from "vue-property-decorator";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import EditAdminRoleDialog from "./EditAdminRoleDialog.vue";
import AdminRoleWithPermissions from "@/utility/admin-users/adminRoleWithPermissions";
import PermissionCheckerMixin from "@/mixins/permissionCheckerMixin";

@Component({
  components: { ConfirmationDialog, EditAdminRoleDialog },
  mixins: [PermissionCheckerMixin]
})
export default class AdminRoles extends Base {
  // 📦 The headers that will be applied on the admin roles table
  private adminRolesTableHeaders = [
    { text: "Role", value: "role" },
    { text: "Permissions", value: "permissions" },
    { text: "", sortable: false, value: "actions" }
  ];

  // 🚩 Flag for whether the delete admin role dialog should be shown
  private showDeleteAdminRoleDialog: boolean = false;

  // 🚩 Flag for whether the edit admin role dialog should be shown
  private showEditAdminRoleDialog: boolean = false;

  // 📦 The currently selected admin tole with permissions that user wants to do actions on
  private selectedAdminRoleWithPermissions: AdminRoleWithPermissions | null = null;

  // 📦 The available admin roles with permissions
  private get adminRolesWithPermissions(): AdminRoles[] {
    return this.$store.state.adminRoles.adminRolesWithPermissions;
  }

  /**
   * 🔨 Formats the admin role permissions in a readable format
   */
  private formatAdminRolePermissions(
    adminRole: AdminRoleWithPermissions
  ): string {
    let displayPermissions = "";
    Object.entries(adminRole.permissions).forEach(([name, permissions]) => {
      displayPermissions +=
        name + " : (" + (permissions as string[]).join(", ") + ")<br>";
    });
    return displayPermissions;
  }

  protected async mounted() {
    super.mounted();
  }
}
