/**
 * Model representing a single open order
 */
export default class OpenOrder {
  public txId: string;
  public side: string;
  public volume: number;
  public price: number;
  public expireTime: number;
  public createTime: number;
  public originalVolume: number;
  public market: string;
  public type: string;
  public currency: string;
  public priceCurrency: string;
  public botName?: string;

  constructor(
    txId: string,
    side: string,
    volume: number,
    price: number,
    expireTime: number,
    createTime: number,
    originalVolume: number,
    market: string,
    type: string,
    currency: string,
    priceCurrency: string
  ) {
    this.txId = txId;
    this.side = side;
    this.volume = volume;
    this.price = price;
    this.expireTime = expireTime;
    this.createTime = createTime;
    this.originalVolume = originalVolume;
    this.market = market;
    this.type = type;
    this.currency = currency;
    this.priceCurrency = priceCurrency;

  }
}
